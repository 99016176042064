import * as React from "react"

const ApplicationMenuSvg = (props) => (
	<svg
		id="ApplicationMenuSvg"
		xmlns="http://www.w3.org/2000/svg"
		x={0}
		y={0}
		width="30"
		height="30"
		viewBox="0 0 30 30"
		style={{
			enableBackground: "new 0 0 30 30",
		}}
		xmlSpace="preserve"
		{...props}
	>
		<style>
			{
				".ApplicationMenuSvg{fill:none;stroke:#000;stroke-width:2.5521;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:3.6364}"
			}
		</style>
		<circle
			cx={15}
			cy={23.8}
			r={0.8}
			style={{
				fill: "none",
				stroke: "#000",
				strokeWidth: 2.7273,
				strokeLinecap: "round",
				strokeLinejoin: "round",
				strokeMiterlimit: 3.6364,
			}}
		/>
		<path
			className="ApplicationMenuSvg"
			d="M12.1 5.4h5.8M20.5 28.6h-11c-2.1 0-3.7-1.7-3.7-3.7V5.2c0-2.1 1.7-3.7 3.7-3.7h11c2.1 0 3.7 1.7 3.7 3.7v19.6c.1 2.1-1.6 3.8-3.7 3.8z"
		/>
	</svg>
)

export default ApplicationMenuSvg
