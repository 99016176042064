import { EVENTS_API } from '../constants/api';
import {  GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    data: {},
    error: '',
};

const FETCH_EVENT_REQUEST = 'FETCH_EVENT_REQUEST';
const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
const FETCH_EVENT_FAILURE = 'FETCH_EVENT_FAILURE';

const fetchEventRequest = () => ({
    type: FETCH_EVENT_REQUEST,
});

const fetchEventSuccess = (data) => ({
    type: FETCH_EVENT_SUCCESS,
    payload: data,
});

const fetchEventFailure = (error) => ({
    type: FETCH_EVENT_FAILURE,
    payload: error,
});

const EventReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_EVENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                error: '',
            };
        case FETCH_EVENT_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_EVENT_REDUCER = (slug) => {
    return (dispatch) => {
        dispatch(fetchEventRequest());
        GET_PROXY(`${EVENTS_API}/${slug}`,
            (success) => {
                dispatch(fetchEventSuccess(success?.data));
            },
            (error) => {
                dispatch(fetchEventFailure(error));
                dispatch(fetchEventFailure(''));
            })
    };
};

export default EventReducer;
