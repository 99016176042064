import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ArrowSvg from '../../../svgs/ArrowSvg';
import { ARROW_ANIMATION_HOVER } from '../../../constants/csses';
import { classNames } from '../../../functions'
const useStyles = makeStyles((theme) => ({
    cont: {
        marginTop: 40,
        position: 'relative',
        zIndex: 4,
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    ul: {
        display: 'flex',
        justifyContent: 'center',
        padding: 0,
        '& li': {

            marginLeft: 10,
            marginRight: 10,
            width: 25,
            transition: 'all 0.2s ease-in',
            height: 25,
            background: theme.palette.background.white,
            border: 'none',
            display: 'inline-block',
            '& button': {
                cursor: 'pointer',
                width: 25,
                height: 25,
                opacity: 0,
            }
        },
        '& .slick-active': {
            background: theme.palette.primary.main,
            borderRadius: '50%',
        }
    },
    arrow: {
        display: 'flex',
        marginLeft: 10,
        marginRight: 10,
        alignItems: 'center',
        cursor: 'pointer',
        '& .arrow-line': {
            background: theme.palette.primary.main,
        },
        '& path': {
            stroke: theme.palette.primary.main,
        },
        '&:hover': {
            ...ARROW_ANIMATION_HOVER
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    leftArrow: {
        transform: 'rotate(180deg)'
    }
}))

const DotsComponent = ({ dots, sliderRef }) => {
    const classes = useStyles();
    const handleBeforeSlide = useCallback(() => {
        if (sliderRef?.current)
            sliderRef.current.slickPrev()
    }, [sliderRef])
    const handleNextSlide = useCallback(() => {
        if (sliderRef?.current)
            sliderRef.current.slickNext()
    }, [sliderRef])
    return (
        <div className={classes.cont}>
            <div
                className={classNames([classes.arrow, classes.leftArrow])}
                onClick={handleBeforeSlide}
            >
                <ArrowSvg />
            </div>
            <ul className={classes.ul}> {dots} </ul>
            <div
                className={classes.arrow}
                onClick={handleNextSlide}
            >
                <ArrowSvg />
            </div>
        </div>
    )
}


DotsComponent.propTypes = {
    sliderRef: PropTypes.object,
    dots: PropTypes.array,
}
export default React.memo(DotsComponent);