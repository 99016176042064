import { SHOPS_API } from '../constants/api';
import {  GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    data: {},
    error: '',
};

const FETCH_SHOP_REQUEST = 'FETCH_SHOP_REQUEST';
const FETCH_SHOP_SUCCESS = 'FETCH_SHOP_SUCCESS';
const FETCH_SHOP_FAILURE = 'FETCH_SHOP_FAILURE';

const fetchShopRequest = () => ({
    type: FETCH_SHOP_REQUEST,
});

const fetchShopSuccess = (data) => ({
    type: FETCH_SHOP_SUCCESS,
    payload: data,
});

const fetchShopFailure = (error) => ({
    type: FETCH_SHOP_FAILURE,
    payload: error,
});

const ShopReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_SHOP_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_SHOP_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                error: '',
            };
        case FETCH_SHOP_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_SHOP_REDUCER = (slug) => {
    return (dispatch) => {
        dispatch(fetchShopRequest());
        GET_PROXY(`${SHOPS_API}/${slug}`,
            (success) => {
                dispatch(fetchShopSuccess(success?.data));
            },
            (error) => {
                dispatch(fetchShopFailure(error));
                dispatch(fetchShopFailure(''));
            })

    };
};

export default ShopReducer;
