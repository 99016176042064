import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import MainReducer from './MainReducer'
import HomePageReducer from './HomePageReducer'
import GastronomyReducer from './GastronomyReducer'
import ShopsReducer from './ShopsReducer'
import PromotionsReducer from './PromotionsReducer'
import NewsReducer from './NewsReducer'
import ServicesReducer from './ServicesReducer'
import ShopReducer from './ShopReducer'
import ServiceReducer from './ServiceReducer'
import PromotionReducer from './PromotionReducer'
import ArticleReducer from './ArticleReducer'
import StylistTipsReducer from './StylistTipsReducer'
import CertificationsReducer from './CertificationsReducer'
import InformationReducer from './InformationReducer'
import ContactReducer from './ContactReducer'
import PlanReducer from './PlanReducer'
import SalesTableReducer from './SalesTableReducer'
import CareerReducer from './CareerReducer'
import CareerOfferReducer from './CareerOfferReducer'
import GalleryReducer from './GalleryReducer'
import SafeShoppingReducer from './SafeShoppingReducer'
import GastronomyShopReducer from './GastronomyShopReducer'
import SearchReducer from './SearchReducer'
import SpecialsPromotionsReducer from './SpecialsPromotionsReducer'
import FashionInspirationReducer from './FashionInspirationReducer'
import EventsReducer from './EventsReducer'
import EventReducer from './EventReducer'
import CustomReducer from './CustomReducer'
import GalleriesReducer from './GalleriesReducer'
import VideosReducer from './VideosReducer'
import AboutReducer from './AboutReducer'
import HelpUkraineReducer from './HelpUkraineReducer'
import HelpUkrainePostReducer from './HelpUkrainePostReducer'
import RegulationReducer from './RegulationReducer'

export default createStore(
    combineReducers({
        MainReducer,
        HomePageReducer,
        GastronomyReducer,
        ShopsReducer,
        PromotionsReducer,
        PromotionReducer,
        ArticleReducer,
        CareerOfferReducer,
        NewsReducer,
        CareerReducer,
        ServicesReducer,
        ShopReducer,
        ServiceReducer,
        AboutReducer,
        StylistTipsReducer,
        CertificationsReducer,
        InformationReducer,
        ContactReducer,
        PlanReducer,
        SalesTableReducer,
        GalleryReducer,
        SafeShoppingReducer,
        SpecialsPromotionsReducer,
        GastronomyShopReducer,
        SearchReducer,
        FashionInspirationReducer,
        EventsReducer,
        EventReducer,
        CustomReducer,
        GalleriesReducer,
        VideosReducer,
        HelpUkraineReducer,
        HelpUkrainePostReducer,
        RegulationReducer,
    }),
    compose(
        applyMiddleware(thunk),
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);
