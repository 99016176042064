import React from 'react'
import { Box, Container, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { classNames} from '../../functions';

const useStyles = makeStyles((theme) => ({
    box: {
        position: 'relative',
        paddingTop: 160,
        paddingBottom: 160,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 80,
            paddingBottom: 80,
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 40,
            paddingBottom: 40,
        },
        
    }
}))

const SpacingContainerComponent = ({ children, className = ''}) => {
    const classes = useStyles();

    return (
        <Box className={classNames([classes.box, className])}>
            <Container>
                {children}
            </Container>
        </Box>
    )
}


SpacingContainerComponent.propTypes = {
    className: PropTypes.string,
}
export default React.memo(SpacingContainerComponent);