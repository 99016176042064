import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { classNames } from '../functions'
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
    img: {
        position: 'absolute',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    }
}))

const AnimImgComponent = ({ src, alt, start = 'top center', loading, delay = 0, className = '', id, from, to }) => {
    const classes = useStyles();

    useEffect(() => {
        if (id) {
            const element = document.querySelector(`#${id}`);
            gsap.fromTo(element, {
                ...from,
            }, {
                ...to,
                stagger: 0.2,
                // duration: 1,
                scrollTrigger: {
                    trigger: element,
                    start,
                }
            }).delay(delay)
        }
    }, [id, from, to, delay, start])
    return (
        <img id={id} src={src} alt={alt} title={alt} className={classNames([classes.img, className, 'anim-imgs'])} loading={loading} />
    )
}


AnimImgComponent.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    start: PropTypes.string,
    className: PropTypes.string,
    loading: PropTypes.string,
    from: PropTypes.object,
    to: PropTypes.object,
}
export default React.memo(AnimImgComponent);