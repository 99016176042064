import { CUSTOM_API } from '../constants/api';
import {  GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    data: { },
    error: '',
};

const FETCH_CUSTOM_REQUEST = 'FETCH_CUSTOM_REQUEST';
const FETCH_CUSTOM_SUCCESS = 'FETCH_CUSTOM_SUCCESS';
const FETCH_CUSTOM_FAILURE = 'FETCH_CUSTOM_FAILURE';

const fetchCustomRequest = () => ({
    type: FETCH_CUSTOM_REQUEST,
});

const fetchCustomSuccess = (data) => ({
    type: FETCH_CUSTOM_SUCCESS,
    payload: data,
});

const fetchCustomFailure = (error) => ({
    type: FETCH_CUSTOM_FAILURE,
    payload: error,
});

const CustomReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_CUSTOM_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_CUSTOM_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                error: '',
            };
        case FETCH_CUSTOM_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_CUSTOM_REDUCER = (slug) => {
    return (dispatch) => {
        dispatch(fetchCustomRequest());
        GET_PROXY(`${CUSTOM_API}/${slug}`,
            (success) => {
                dispatch(fetchCustomSuccess(success?.data));
            },
            (error) => {
                dispatch(fetchCustomFailure(error));
                dispatch(fetchCustomFailure(''));
            })
    };
};

export default CustomReducer;
