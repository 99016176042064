import { HELP_UKRAINE_API } from '../constants/api';
import {  GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    data: {  },
    error: '',
};

const FETCH_HELP_UKRAINE_POST_REQUEST = 'FETCH_HELP_UKRAINE_POST_REQUEST';
const FETCH_HELP_UKRAINE_POST_SUCCESS = 'FETCH_HELP_UKRAINE_POST_SUCCESS';
const FETCH_HELP_UKRAINE_POST_FAILURE = 'FETCH_HELP_UKRAINE_POST_FAILURE';

const fetchHelpUkrainePostRequest = () => ({
    type: FETCH_HELP_UKRAINE_POST_REQUEST,
});

const fetchHelpUkrainePostSuccess = (data) => ({
    type: FETCH_HELP_UKRAINE_POST_SUCCESS,
    payload: data,
});

const fetchHelpUkrainePostFailure = (error) => ({
    type: FETCH_HELP_UKRAINE_POST_FAILURE,
    payload: error,
});

const HelpUkrainePostReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_HELP_UKRAINE_POST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_HELP_UKRAINE_POST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                error: '',
            };
        case FETCH_HELP_UKRAINE_POST_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_HELP_UKRAINE_POST_REDUCER = (slug) => {
    return (dispatch) => {
        dispatch(fetchHelpUkrainePostRequest());
        GET_PROXY(`${HELP_UKRAINE_API}/${slug}`, (success) => {
    dispatch(fetchHelpUkrainePostSuccess(success?.data));
        }, (error) => {
            dispatch(fetchHelpUkrainePostFailure(error));
            dispatch(fetchHelpUkrainePostFailure(''));
        })
    };
};

export default HelpUkrainePostReducer;
