import { GALLERY_API } from '../constants/api';
import {  GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    meta: {},
    data: [],
    error: '',
};

const FETCH_GALLERIES_REQUEST = 'FETCH_GALLERIES_REQUEST';
const FETCH_GALLERIES_SUCCESS = 'FETCH_GALLERIES_SUCCESS';
const FETCH_GALLERIES_FAILURE = 'FETCH_GALLERIES_FAILURE';

const fetchGalleriesRequest = () => ({
    type: FETCH_GALLERIES_REQUEST,
});

const fetchGalleriesSuccess = (data) => ({
    type: FETCH_GALLERIES_SUCCESS,
    payload: data,
});

const fetchGalleriesFailure = (error) => ({
    type: FETCH_GALLERIES_FAILURE,
    payload: error,
});

const GalleriesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_GALLERIES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_GALLERIES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: [

                    ...(payload?.meta?.current_page > 1 ? state?.data : []),
                    ...(payload?.data || []),
                ],
                meta: payload.meta,
                error: '',
            };
        case FETCH_GALLERIES_FAILURE:
            return {
                meta: {},
                loading: false,
                data: [],
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_GALLERIES_REDUCER = (page = 1) => {
    return (dispatch) => {
        dispatch(fetchGalleriesRequest());
        GET_PROXY(`${GALLERY_API}?page=${page}`, 
            (success) => {
                dispatch(fetchGalleriesSuccess(success));
            },
            (error) => {
                dispatch(fetchGalleriesFailure(error));
                dispatch(fetchGalleriesFailure(''));
            })
    };
};

export default GalleriesReducer;
