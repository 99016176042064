import { GASTRONOMY_API } from '../constants/api';
import {  GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    postLoading: false,
    categoryLoading: false,
    meta: {},
    data: {},
    error: '',
};

const FETCH_GASTRONOMY_REQUEST = 'FETCH_GASTRONOMY_REQUEST';
const FETCH_GASTRONOMY_POST_REQUEST = 'FETCH_GASTRONOMY_POST_REQUEST';
const FETCH_GASTRONOMY_CATEGORY_REQUEST = 'FETCH_GASTRONOMY_CATEGORY_REQUEST';
const FETCH_GASTRONOMY_SUCCESS = 'FETCH_GASTRONOMY_SUCCESS';
const FETCH_GASTRONOMY_FAILURE = 'FETCH_GASTRONOMY_FAILURE';

const fetchGastronomyRequest = () => ({
    type: FETCH_GASTRONOMY_REQUEST,
});
const fetchGastronomyPostRequest = () => ({
    type: FETCH_GASTRONOMY_POST_REQUEST,
});
const fetchGastronomyCategoryRequest = () => ({
    type: FETCH_GASTRONOMY_CATEGORY_REQUEST,
});

const fetchGastronomySuccess = (data) => ({
    type: FETCH_GASTRONOMY_SUCCESS,
    payload: data,
});

const fetchGastronomyFailure = (error) => ({
    type: FETCH_GASTRONOMY_FAILURE,
    payload: error,
});

const GastronomyReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_GASTRONOMY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_GASTRONOMY_POST_REQUEST:
            return {
                ...state,
                postLoading: true,
            };
        case FETCH_GASTRONOMY_CATEGORY_REQUEST:
            return {
                ...state,
                categoryLoading: true,
            };
        case FETCH_GASTRONOMY_SUCCESS:
            return {
                ...state,
                loading: false,
                postLoading: false,
                categoryLoading: false,
                data: {
                    ...payload?.data,
                    shops: [
                        ...(payload?.meta?.current_page > 1 ? state?.data?.shops : []),
                        ...(payload?.data?.shops || []),
                    ]
                },
                meta: payload.meta,
                error: '',
            };
        case FETCH_GASTRONOMY_FAILURE:
            return {
                meta: {},
                loading: false,
                postLoading: false,
                categoryLoading: false,
                data: {},
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_GASTRONOMY_REDUCER = (name = '', categories = '', promotions = '', lettersFilter = '', page = '', callback = () => { }) => {
    return (dispatch) => {
        if (page && page === 1)
            dispatch(fetchGastronomyCategoryRequest())
        else if (page && page > 1)
            dispatch(fetchGastronomyPostRequest());
        else
            dispatch(fetchGastronomyRequest());

        GET_PROXY(`${GASTRONOMY_API}?page=${page}&filter[categories]=${categories}&filter[lettersFilter]=${lettersFilter}&filter[promotions]=${promotions}&filter[name]=${name}`,
            (success) => {
                callback()
                dispatch(fetchGastronomySuccess(success));
            },
            (error) => {
                dispatch(fetchGastronomyFailure(error));
                dispatch(fetchGastronomyFailure(''));
            })
    };
};

export default GastronomyReducer;
