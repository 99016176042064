import { ABOUT_API } from '../constants/api';
import {  GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    meta: {},
    data: {
        gallery: [],
    },
    error: '',
};

const FETCH_ABOUT_REQUEST = 'FETCH_ABOUT_REQUEST';
const FETCH_ABOUT_SUCCESS = 'FETCH_ABOUT_SUCCESS';
const FETCH_ABOUT_FAILURE = 'FETCH_ABOUT_FAILURE';

const fetchInfromationRequest = () => ({
    type: FETCH_ABOUT_REQUEST,
});

const fetchInfromationSuccess = (data) => ({
    type: FETCH_ABOUT_SUCCESS,
    payload: data,
});

const fetchInfromationFailure = (error) => ({
    type: FETCH_ABOUT_FAILURE,
    payload: error,
});

const AboutReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_ABOUT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_ABOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    ...payload.data,
                    gallery: [
                        ...(state?.data?.gallery || []),
                        ...(payload?.data?.gallery || []),
                    ]
                },
                meta: payload.meta,
                error: '',
            };
        case FETCH_ABOUT_FAILURE:
            return {
                loading: false,
                meta: {},
                data: {},
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_ABOUT_REDUCER = (page) => {
    return (dispatch) => {
        dispatch(fetchInfromationRequest());
        // console.log('work')
        // { page}
        GET_PROXY(`${ABOUT_API}?page=${page}`, (success) => {
            dispatch(fetchInfromationSuccess(success));
        }, (error) => {
            dispatch(fetchInfromationFailure(error));
            dispatch(fetchInfromationFailure(''));
        })
    };
};

export default AboutReducer;
