import { SALES_TABLE_API } from '../constants/api';
import {  GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    postLoading: false,
    meta: {},
    data: {},
    error: '',
};

const FETCH_SALES_TABLE_REQUEST = 'FETCH_SALES_TABLE_REQUEST';
const FETCH_SALES_TABLE_POST_REQUEST = 'FETCH_SALES_TABLE_POST_REQUEST';
const FETCH_SALES_TABLE_SUCCESS = 'FETCH_SALES_TABLE_SUCCESS';
const FETCH_SALES_TABLE_FAILURE = 'FETCH_SALES_TABLE_FAILURE';

const fetchSalesTableRequest = () => ({
    type: FETCH_SALES_TABLE_REQUEST,
});
const fetchSalesTablePostRequest = () => ({
    type: FETCH_SALES_TABLE_POST_REQUEST,
});

const fetchSalesTableSuccess = (data) => ({
    type: FETCH_SALES_TABLE_SUCCESS,
    payload: data,
});

const fetchSalesTableFailure = (error) => ({
    type: FETCH_SALES_TABLE_FAILURE,
    payload: error,
});

const SalesTableReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_SALES_TABLE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_SALES_TABLE_POST_REQUEST:
            return {
                ...state,
                postLoading: true,
            };
        case FETCH_SALES_TABLE_SUCCESS:
            return {
                ...state,
                loading: false,
                postLoading: false,
                data: {
                    ...state.data,
                    ...payload.data,
                    promotions: [
                        ...(payload?.meta?.current_page > 1 ? state?.data?.promotions : []),
                        ...(payload?.data?.promotions || []),
                    ],
                },
                meta: payload.meta,
                error: '',
            };
        case FETCH_SALES_TABLE_FAILURE:
            return {
                meta: {},
                loading: false,
                postLoading: false,
                data: [],
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_SALES_TABLE_REDUCER = (page) => {
    return (dispatch) => {
        if (page)
            dispatch(fetchSalesTablePostRequest());
        else
            dispatch(fetchSalesTableRequest());
        GET_PROXY(`${SALES_TABLE_API}?page=${page}`,
            (success) => {
                dispatch(fetchSalesTableSuccess(success));
            },
            (error) => {
                dispatch(fetchSalesTableFailure(error));
                dispatch(fetchSalesTableFailure(''));
            })
    };
};

export default SalesTableReducer;
