import { createMuiTheme } from "@material-ui/core/styles";
import { BLUR_EFFECT } from "../constants/csses";

//  main: "#4FB05C",
// dark: "#208A7C",
const primaryDarkColor = "#827fe9";
const primaryColor = "#827fe9";
// const primaryDarkColor =  '#b13534';
// const primaryColor =  '#827fe9';
const mobile = "@media (max-width: 600px)";
const lessMobile = "@media (max-width: 450px)";
export const createTheme = () => {
  const theme = createMuiTheme({
    "*": {
      fontFamily: '"lato", "sans-serif"',
      fontDisplay: "swap",
    },
    typography: {
      fontSize: 12,
      fontFamily: '"lato", "sans-serif"',
      fontDisplay: "swap",
      button: {
        fontSize: 16,
        fontWeight: "900",
        [mobile]: {
          fontSize: 14,
        },
      },
      body1: {
        fontSize: 16,
        "@media (max-width: 1200px)": {
          fontSize: 14,
        },
        [mobile]: {
          fontSize: 14,
        },
        [lessMobile]: {
          fontSize: 12,
        },
      },
      body2: {
        fontSize: 14,
        fontWeight: "normal",
        [mobile]: {
          fontSize: 12,
        },
      },
      h3: {
        fontSize: 40,
        marginBottom: 20,
        fontWeight: "900",
        textTransform: "uppercase",
        [mobile]: {
          fontSize: 24,
        },
        [lessMobile]: {
          fontSize: 18,
        },
      },
      h4: {
        fontSize: 24,
        marginBottom: 20,
        fontWeight: "900",
        textTransform: "uppercase",
        [mobile]: {
          fontSize: 18,
        },
        [lessMobile]: {
          fontSize: 14,
        },
      },
      h2: {
        fontSize: 60,
        fontWeight: "900",
        marginBottom: 80,
        textTransform: "uppercase",

        "@media (max-width: 1300px)": {
          marginBottom: 40,
          fontSize: 40,
        },
        [mobile]: {
          fontSize: 40,
          marginBottom: 40,
        },
        [lessMobile]: {
          fontSize: 24,
        },
        // [t.breakpoints.down('sm')]: {
        //     fontSize: 40,
        // },
      },
      h1: {
        fontSize: 120,
        fontFamily: '"lato", "sans-serif"',
        fontDisplay: "swap",
        fontWeight: 300,
        "@media (max-width: 1200px)": {
          fontSize: 80,
        },
        [mobile]: {
          fontSize: 56,
        },
        [lessMobile]: {
          fontSize: 32,
        },
        textTransform: "uppercase",
      },
      h5: {
        fontSize: 18,
        marginBottom: 10,
        fontWeight: "bold",
        textTransform: "uppercase",
      },
      // fontFamily: '"Boing", "Roboto", "Helvetica", "Arial", "sans-serif"',
    },
    palette: {
      plan: {},
      happy: {
        food: "#827fe9",
        fitness: "#70C82F",
        cinema: "#EB008B",
        bowling: "#FCD001",
      },
      primary: {
        // dark: '#305E9A',
        dark: primaryDarkColor,
        main: primaryColor,
        light: primaryColor,
        // light: '#00000046',
      },
      secondary: {
        // dark: '#fff',
        main: "#39AB35",
        // light: '#ffeb3b',
        // orange: '#FF963C',
      },
      app: {
        main: "#7952D5",
        light: "#AA9BEF",
      },
      background: {
        white: "#ffffff",
        default: "#ffffff",
        gray: "#EFEFEF",
        lightestGray: "#fafafa",
        darkGray: "#e9e9e9",
        lightGray: "#F7F7F7",
        black: "#000000",
        sliderBackground: "#f0f0f0",
        appGradient: "linear-gradient(135deg, #77A6E5 0%, #8B50EB 100%)",
      },
      chip: {
        primary: "#00000040",
      },
      text: {
        primary: "#505357",
        black: "#000000",
        white: "#FFFFFF",
      },
      other: {
        yellow: "#FCD001",
      },
    },
    overrides: {
      MuiBackdrop: {
        root: {
          ...BLUR_EFFECT,
        },
      },
      MuiPaper: {
        elevation1: {
          transition: "all 0.2s ease-in",
          background: "#FFFFFF",
          // overflow: 'hidden',
          cursor: "pointer",
          borderRadius: 0,
          border: `none`,
          boxShadow: "0 5px 20px #00000020",
          "&:hover": {
            background: primaryColor,
            borderRadius: "50%",
          },
        },
        elevation2: {
          transition: "all 0.2s ease-in",
          background: "#FFFFFF",
          // overflow: 'hidden',
          borderRadius: 0,
          border: `none`,
          boxShadow: "0 5px 20px #00000020",
        },
      },
    },
    props: {
      MuiContainer: {
        // maxWidth: "md",
      },
    },
  });
  return {
    ...theme,
  };
};

const MainTheme = createTheme();
export default MainTheme;
