import React from 'react'
import { makeStyles } from '@material-ui/core';
import LinkComponent from './LinkComponent';
import { MAIN_ROUTE } from '../constants/routes';
import LogoSvg from '../svgs/LogoSvg';
import { classNames } from '../functions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    logo: {
        paddingTop: 8,
        paddingBottom: 5,
        width: 100, // 150
        transition: 'all 0.2s ease-in',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: 120, // 120
            paddingTop: 13,
            paddingBottom: 10,
        },
    }
}))

const LogoComponent = ({ className = ''}) => {
 const classes = useStyles();
 const {t} = useTranslation();
    return (
        <div>
            <LinkComponent to={MAIN_ROUTE} title={t('main_page.title')}>
                <LogoSvg className={classNames([classes.logo, className])} />
            </LinkComponent>
        </div>
    )
}

export default React.memo(LogoComponent);