import React, { useCallback, useEffect } from 'react'
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import MainContainerComponent from '../../components/structure/MainContainerComponent';
// import HappyPortComponent from '../../components/HappyPortComponent';
import SubtitleComponent from '../../components/SubtitleComponent';
import MainSliderComponent from './components/MainSliderComponent';
import SectionComponent from './components/SectionComponent';
import EssentialBackSvg from '../../svgs/EssentialBackSvg';
import { useTranslation } from 'react-i18next';
import CardsComponent from '../../components/CardsComponent';
// import FashionInspirationSvg from '../../svgs/FashionInspirationSvg';
import {
    PLAN_ROUTE,
    NEWS_ROUTE,
    PROMOTIONS_ROUTE,
    COMFORT_ROUTE,
    GIFT_ROUTE,
    // FASHION_INSPIRATION_ROUTE,
    SALES_TABLE_ROUTE,
    ENTERTAINMENT_ROUTE,
    SPECIAL_PROMOTIONS_ROUTE,
    APPLICATION_ROUTE,
    // HELP_UKRAINE_ROUTE,
} from '../../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import SalesSvg from '../../svgs/SalesSvg';
import { HORIZONTAL_CARD } from '../../constants';
// import NewsListComponent from '../../components/NewsListComponent';
import HappyPortBackSvg from '../../svgs/HappyPortBackSvg';
import SalesAnimElComponent from './components/SalesAnimElComponent';
import CircleComponent from '../../components/CircleComponent';
import AnimImgComponent from '../../components/AnimImgComponent';
import DogImg from '../../imgs/dog.webp'
import LinkBtnComponent from '../../components/LinkBtnComponent';
import { FETCH_HOME_DATA_REDUCER } from '../../reducers/HomePageReducer';
import LazyLoad from 'react-lazyload';
import preloadPages from '../../functions/preloadPages';
import loadable from '@loadable/component'
// import ApplicationSvg from '../../svgs/ApplicationSvg';
// import FlagSvg from '../../svgs/FlagSvg';
// import MapSvg from '../../svgs/MapSvg';
// import ConveniencesSvg from '../../svgs/ConveniencesSvg';
// import GiftSvg from '../../svgs/GiftSvg';
const GiftSvg = loadable(() => import('../../svgs/GiftSvg'))
const ConveniencesSvg = loadable(() => import('../../svgs/ConveniencesSvg'))
const MapSvg = loadable(() => import('../../svgs/MapSvg'))
const ApplicationSvg = loadable(() => import('../../svgs/ApplicationSvg'))
const NewsListComponent = loadable(() => import('../../components/NewsListComponent'))
const HappyPortComponent = loadable(() => import('../../components/HappyPortComponent'))

const useStyles = makeStyles((theme) => ({
    essential: {
        height: '80vh',
        marginTop: -20,
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
    happyPortBack: {
        marginTop: -220,
        background: theme.palette.background.lightestGray,
        [theme.breakpoints.down('sm')]: {
            marginTop: -20,
        },
    },
    contEssential: {
        paddingTop: 180,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 60,
        },

    },
    salesSection: {
        position: 'relative',
    },
    salesCont: {
        paddingTop: 240,
        paddingBottom: 80,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 80,
            paddingBottom: 20,
        },
    },
    newsSection: {
        background: theme.palette.background.lightestGray,
        paddingBottom: 400,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 160,
        },
    },
    newsCont: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: '40px !important',
        },
    },
    salesGrid: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    happyPortCont: {
        paddingTop: 0,
        marginTop: -100,
    },
    happyPortBox: {
        marginTop: 40,
        paddingBottom: 250,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 0,
            marginTop: 0,
        },
    },
    happyPortBackSpacing: {
        height: '90%',
        position: 'absolute',
        bottom: 0,

        width: '100%',
        background: '#f0f0f0'
    },
    dog: {
        height: 400,
        top: -260,
        right: 0,
    },
    circle: {
        zIndex: 0,
    },
    paperClassName: {
        background: theme.palette.background.appGradient,
        color: theme.palette.text.white,
        textTransform: "",
        '& path, circle': {
            stroke: theme.palette.text.white, 
        },
    }
}))

const MainPage = (props) => {
    const classes = useStyles();
    const { data, loading } = useSelector(state => state.HomePageReducer);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(FETCH_HOME_DATA_REDUCER());
    }, [dispatch])

    const handlePreloadPages = useCallback(() => {
        preloadPages();
    }, [])

    return (
        <MainContainerComponent>
            <MainSliderComponent
                data={data?.slider}
                loading={loading}
            />
            <SectionComponent
                classNameSection={classes.essential}
                Background={EssentialBackSvg}
                classNameCont={classes.contEssential}
                title={t('main_page.essential')}
            >
                <CircleComponent
                    size={40}
                    top={150}
                    rellax
                    right={'10%'}
                    primary
                />
                <CircleComponent
                    size={95}
                    top={150}
                    rellax
                    right={-95}
                    primary
                />
                <CircleComponent
                    size={20}
                    rellax
                    top={170}
                    right={'calc(10% + 50px)'}
                    secondary
                />
                <div onMouseOver={handlePreloadPages}>
                    <CardsComponent
                        loading={loading}
                        data={[
                            // {
                            //     icon: SalesSvg,
                            //     name: t('sales_table_page.title'),
                            //     to: SALES_TABLE_ROUTE,
                            // }, 
                            {
                                name: t('main_page.plan'),
                                icon: MapSvg,
                                to: PLAN_ROUTE,
                            },
                            {
                                name: t('main_page.conveniences'),
                                icon: ConveniencesSvg,
                                to: COMFORT_ROUTE,
                            },
                            {
                                name: t('main_page.gift_card'),
                                icon: GiftSvg,
                                to: GIFT_ROUTE,
                            },
                          
                            {
                                name: t('main_page.application'),
                                icon: ApplicationSvg,
                                to: APPLICATION_ROUTE,
                                paperClassName: classes.paperClassName
                            },
                            // ...(data.special_promotions 
                            //     ? [{
                            //     name: t('main_page.special_occasions'),
                            //     icon: FashionInspirationSvg,
                            //     // icon: FlagSvg,
                            //     to: `${SPECIAL_PROMOTIONS_ROUTE}/${data.special_promotions.slug}`,
                            //     }] : []
                            // ),
                            // {
                            //     name: t('main_page.ukraine_help'),
                            //     icon: FlagSvg,
                            //     to: HELP_UKRAINE_ROUTE,
                            // },
                        ]}
                    />
                </div>
            </SectionComponent>

            <Box className={classes.salesSection}>
                <CircleComponent
                    size={130}
                    top={-150}
                    left={-75}
                    secondary
                    rellax
                />
                <CircleComponent
                    size={95}
                    top={0}
                    right={120}
                    primary
                    rellax
                />
                <CircleComponent
                    size={30}
                    top={80}
                    right={80}
                    secondary
                    rellax
                />
                <CircleComponent
                    size={140}
                    bottom={-70}
                    right={-70}
                    primary
                    rellax
                />
                <Container className={classes.salesCont}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12} className={classes.salesGrid}>
                            <SalesAnimElComponent />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <SubtitleComponent
                                to={PROMOTIONS_ROUTE}
                                title={t('main_page.promotions')}
                            />
                            <CardsComponent
                                md={6}
                                //    loading={true}
                                slug={PROMOTIONS_ROUTE}
                                data={[
                                    ...(data?.special_promotions
                                        ? [{
                                            src: data.special_promotions.src,
                                            name: data.special_promotions.name,
                                            color: data.special_promotions.color,
                                            withoutSlug: true,
                                            showName: true,
                                            to: `${SPECIAL_PROMOTIONS_ROUTE}/${data.special_promotions.slug}`,
                                            type: HORIZONTAL_CARD
                                        },
                                        {
                                            icon: SalesSvg,
                                            name: t('sales_table_page.title'),
                                            withoutSlug: true,
                                            to: SALES_TABLE_ROUTE,
                                            type: HORIZONTAL_CARD
                                        },
                                        ]
                                        : [{
                                            icon: SalesSvg,
                                            name: t('sales_table_page.title'),
                                            withoutSlug: true,
                                            to: SALES_TABLE_ROUTE,
                                            type: HORIZONTAL_CARD
                                        }]
                                    ),
                                    ...(data?.promotions?.length ? data?.promotions : []),

                                ]}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <SectionComponent
                moreTo={NEWS_ROUTE}
                classNameSection={classes.newsSection}
                classNameCont={classes.newsCont}
                title={t('main_page.news')}
                animElements={<>
                    <CircleComponent
                        size={400}
                        left={-200}
                        top={100}
                        border
                        zIndex={0}
                        secondary
                    />
                    <CircleComponent
                        size={100}
                        left={-50}
                        top={450}
                        primary
                    />
                </>}
            >
                <NewsListComponent

                    // loading={true}
                    data={data?.news}
                    slug={NEWS_ROUTE}
                />
            </SectionComponent>
            <SectionComponent
                Background={HappyPortBackSvg}
                title={t('main_page.happy_port')}
                classNameBack={classes.happyPortBack}
                classNameCont={classes.happyPortCont}
                animElements={<>
                    <Box className={classes.happyPortBackSpacing}></Box>
                    <CircleComponent
                        size={800}
                        right={-400}
                        top={100}
                        border
                        className={classes.circle}
                        secondary
                    />
                </>}
            >
                <CircleComponent
                    size={40}
                    top={-100}
                    right={'48%'}
                    primary
                />
                <CircleComponent
                    size={70}
                    top={-70}
                    right={'40%'}
                    primary
                />
                <AnimImgComponent
                    delay={2}
                    from={{
                        opacity: 0,
                        transform: 'scale(1.1)',
                        top: -460,
                    }}
                    to={{
                        opacity: 1,
                        transform: 'scale(1)',
                        top: -260,

                    }}
                    loading={'eager'}
                    id='dogImg'
                    alt={t('anim_imgs.dog')}
                    start={'top center+=200'}
                    className={classes.dog}
                    src={DogImg}
                />
                <Box className={classes.happyPortBox}>
                    <LazyLoad once offset={[200, 0]} placeholder={<div style={{ height: 800, width: '100%' }}></div>}>
                        <HappyPortComponent />
                    </LazyLoad>
                    <Box display='flex' justifyContent='center' mt="40px" pb='20px'>
                        <LinkBtnComponent
                            to={ENTERTAINMENT_ROUTE}
                        />
                    </Box>
                </Box>
            </SectionComponent>

        </MainContainerComponent>
    )
}


MainPage.propTypes = {
    name: PropTypes.string,
}
export default React.memo(MainPage);