import * as React from "react"

function FacebookSvg(props) {
    return (
        <svg
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            width={30}
            height={30}
            clipRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <path
                d="M74.857 4.806H61.299C48.903 4.806 38.7 15.008 38.7 27.403V40.96H25.143V59.04h13.558v36.155H56.78V59.04h13.558l4.52-18.078H56.779V27.403c0-2.48 2.04-4.52 4.52-4.52h13.558V4.807z"
                fill="none"
                stroke="#000"
                strokeWidth={9.03882}
            />
        </svg>
    )
}

export default FacebookSvg
