import React, { useMemo } from 'react'
import { Box, makeStyles, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { classNames } from '../functions'
const useStyles = makeStyles((theme) => ({
    circle: {
        borderRadius: '50%',
        position: 'absolute', 
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    }
}))

const CircleComponent = ({ zIndex = 1, left, secondary, primary, rellax, top, bottom, right, size, border, borderColor, color, className = '' }) => {
    const classes = useStyles();
    const theme = useTheme();
    const backgroundColor = useMemo(() => {
        switch (true) {
            case primary:
                return theme.palette.primary.main;
            case secondary:
                return theme.palette.secondary.main;
            default:
                return borderColor || color

        }
    }, [theme, secondary, primary, borderColor, color])
    return (
        <Box
            className={classNames(['circle-c', classes.circle, rellax ? 'rellax' : '', className, ])}
            width={size}
            height={size}
            top={top}
            left={left}
            zIndex={zIndex}
            right={right}
            bottom={bottom}
            border={border ? '1px solid' : border}
            borderColor={backgroundColor}
            style={{ backgroundColor: border ? '' : backgroundColor }}
        ></Box>
    )
}


CircleComponent.propTypes = {
    border: PropTypes.bool,
    rellax: PropTypes.bool,
}
export default React.memo(CircleComponent);