import React, { useCallback, useEffect, useState } from 'react'
import { Box, Container, IconButton, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector } from 'react-redux';
import HamburgerBtnComponent from '../HamburgerBtnComponent';
import LogoComponent from '../LogoComponent';
import preloadPages from '../../functions/preloadPages';
import FacebookSvg from '../../svgs/FacebookSvg';
import InstagramSvg from '../../svgs/InstagramSvg';
import { FACEBOOK_LINK, INSTAGRAM_LINK, YOUTUBE_LINK } from '../../constants';
import SocialHeaderBtn from './SocialHeaderBtn';
import YouTubeSvg from '../../svgs/YouTubeSvg';
import loadable from '@loadable/component'
import { APPLICATION_ROUTE } from '../../constants/routes';
import ApplicationMenuSvg from '../../svgs/ApplicationMenuSvg';

const SearchComponent = loadable(() => import('../SearchComponent'))
const MenuItemComponent = loadable(() => import('./MenuItemComponent'))
const MobileMenuComponent = loadable(() => import('./MobileMenuComponent'))

const useStyles = makeStyles((theme) => ({
    appBar: {
        background: theme.palette.background.white,
        '& .small-logo': {
            width: 80,
        }
    },
    nav: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    menu: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    iconBtn: {
        color: 'black',
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            marginLeft: 6,
        },
    },
    icon: {
        width: 25,
        height: 25,
        [theme.breakpoints.down('md')]: {
            width: 20,
            height: 20,
        },
    },

    youtubeIcon: {
        width: 24,
        height: 20,
        [theme.breakpoints.down('md')]: {
            width: 24,
            height: 16,
        },
    },
    ukr: {
        marginLeft: theme.spacing(1),
        height: 50,
    },
    logoBox: {
        display: 'flex',
        alignItems: 'center',
    },
}))

const AppBarComponent = (props) => {
    const classes = useStyles();
    const { data } = useSelector((state) => state.MainReducer)
    const [scroll, setScroll] = useState(false);
    // const [lastScrollTop, setLastScrollTop] = useState(0);
    const [show, setShow] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'))
    const toggleShow = useCallback(() => {
        setShow(!show)
    }, [show])

    const handleToggleSearch = useCallback(() => {
        setShowSearch(!showSearch)
    }, [showSearch])



    const scrollFun = useCallback(() => {
        setScroll(window.scrollY > 260);
    }, [])
    useEffect(() => {
        window.addEventListener("scroll", scrollFun);
        return () => {
            window.removeEventListener("scroll", scrollFun);
        }
        // eslint-disable-next-line 
    }, [scrollFun]);

    const handlePreloadPages = useCallback(() => {
        preloadPages();
    }, [])
    return (
        <div className={classes.appBar} onMouseOver={handlePreloadPages}>
            {matches ?
                <MobileMenuComponent
                    toggleShow={toggleShow}
                    show={show}
                />
                : null
            }
            <Container className={classes.nav} component='nav'>
                <Box className={classes.logoBox}>
                    <LogoComponent
                        className={scroll ? 'small-logo' : classes.logoWithUkr}
                    />
                </Box>
                <HamburgerBtnComponent
                    toggleShow={toggleShow}
                    show={show}
                />
                {!matches
                    ? <div className={classes.menu}>
                        {data?.menu?.length
                            ? data.menu.map(({ title, to, external_link }, key) =>
                                <MenuItemComponent
                                    to={to}
                                    key={key}
                                    external_link={external_link}
                                    title={title}
                                />
                            )
                            : null
                        }
                        <IconButton className={classes.iconBtn} size='small' onClick={handleToggleSearch}>
                            <SearchIcon className={classes.icon} />
                        </IconButton>
                        <SocialHeaderBtn
                            to={YOUTUBE_LINK}
                            title='YouTube'
                            classNameIcon={classes.youtubeIcon}
                            Icon={YouTubeSvg}
                        />
                        <SocialHeaderBtn
                            to={FACEBOOK_LINK}
                            title='Facebook'
                            Icon={FacebookSvg}
                        />
                        <SocialHeaderBtn
                            to={INSTAGRAM_LINK}
                            title='Instagram'
                            Icon={InstagramSvg}
                        />
                        <SocialHeaderBtn
                            to={APPLICATION_ROUTE}
                            title='Galaxy App'
                            notExternal
                            Icon={ApplicationMenuSvg}
                        />
                    </div>
                    : null}
                <SearchComponent
                    open={showSearch}
                    handleToggleSearch={handleToggleSearch}
                />
            </Container>
        </div>
    )
}


export default React.memo(AppBarComponent);