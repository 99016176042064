import { REGULATION_API } from '../constants/api';
import { GET_PROXY } from '../functions/superagentSending';

const initialState = {
	loading: true,
	data: {},
	error: '',
};

const FETCH_REGULATION_REQUEST = 'FETCH_REGULATION_REQUEST';
const FETCH_REGULATION_SUCCESS = 'FETCH_REGULATION_SUCCESS';
const FETCH_REGULATION_FAILURE = 'FETCH_REGULATION_FAILURE';

const fetchRegulationRequest = () => ({
	type: FETCH_REGULATION_REQUEST,
});

const fetchRegulationSuccess = (data) => ({
	type: FETCH_REGULATION_SUCCESS,
	payload: data,
});

const fetchRegulationFailure = (error) => ({
	type: FETCH_REGULATION_FAILURE,
	payload: error,
});

const RegulationReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_REGULATION_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_REGULATION_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
				error: '',
			};
		case FETCH_REGULATION_FAILURE:
			return {
				loading: false,
				data: {},
				error: payload,
			};
		default:
			return state;
	}
};

export const FETCH_REGULATION_REDUCER = (slug) => {
	return (dispatch) => {
		dispatch(fetchRegulationRequest());
		GET_PROXY(`${REGULATION_API}/${slug}`,
			(success) => {
				dispatch(fetchRegulationSuccess(success?.data));
			},
			(error) => {
				dispatch(fetchRegulationFailure(error));
				dispatch(fetchRegulationFailure(''));
			})
	};
};

export default RegulationReducer;
