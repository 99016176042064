import { STYLIST_TIPS_API } from '../constants/api';
import {  GET_PROXY, POST } from '../functions/superagentSending';

const initialState = {
    loading: true,
    formLoading: false,
    data: {},
    error: '',
};

const FETCH_STYLIST_TIPS_REQUEST = 'FETCH_STYLIST_TIPS_REQUEST';
const SEND_STYLIST_TIPS_REQUEST = 'SEND_STYLIST_TIPS_REQUEST';
const FETCH_STYLIST_TIPS_SUCCESS = 'FETCH_STYLIST_TIPS_SUCCESS';
const SEND_STYLIST_TIPS_SUCCESS = 'SEND_STYLIST_TIPS_SUCCESS';
const FETCH_STYLIST_TIPS_FAILURE = 'FETCH_STYLIST_TIPS_FAILURE';

const fetchStylistTipsRequest = () => ({
    type: FETCH_STYLIST_TIPS_REQUEST,
});
const sendStylistTipsRequest = () => ({
    type: SEND_STYLIST_TIPS_REQUEST,
});

const fetchStylistTipsSuccess = (data) => ({
    type: FETCH_STYLIST_TIPS_SUCCESS,
    payload: data,
});
const sendStylistTipsSuccess = (data) => ({
    type: SEND_STYLIST_TIPS_SUCCESS,
    payload: data,
});

const fetchStylistTipsFailure = (error) => ({
    type: FETCH_STYLIST_TIPS_FAILURE,
    payload: error,
});

const StylistTipsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_STYLIST_TIPS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SEND_STYLIST_TIPS_REQUEST:
            return {
                ...state,
                formLoading: true,
            };
        case FETCH_STYLIST_TIPS_SUCCESS:
            return {
                ...state,
                loading: false,
                formLoading: false,
                data: payload,
                error: '',
            };
        case SEND_STYLIST_TIPS_SUCCESS:
            const freeDates = state?.data?.freeDates?.length
                ? state.data.freeDates.map((item) => {
                    if (item?.date === payload.selectedDate)
                        if (item.time)
                            item.time = item.time.filter((time) => time.id !== payload.id)
                    return item
                })
                : []
            return {
                ...state,
                loading: false,
                formLoading: false,
                data: {
                    ...state.date,
                    freeDates
                },
                error: '',
            };
        case FETCH_STYLIST_TIPS_FAILURE:
            return {
                loading: false,
                formLoading: false,
                data: {},
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_STYLIST_TIPS_REDUCER = () => {
    return (dispatch) => {
        dispatch(fetchStylistTipsRequest());
        GET_PROXY(STYLIST_TIPS_API,
            (success) => {
                dispatch(fetchStylistTipsSuccess(success?.data));
            },
            (error) => {
                fetchStylistTipsFailure(error);
                fetchStylistTipsFailure('');
            })
    };
};
export const SEND_STYLIST_TIPS_REDUCER = (body, selectedDate, callback = () => { }) => {
    return (dispatch) => {
        dispatch(sendStylistTipsRequest());

        const fd = new FormData();
        Object.keys(body).forEach((item) => {
            if (item !== 'files')
                fd.append(item, body[item])
            else
                body[item].forEach((file) => {
                    fd.append(`${item}[]`, file)
                })

        })

        POST(STYLIST_TIPS_API, fd)
            .then((success) => {
                dispatch(sendStylistTipsSuccess({ selectedDate, id: body.id }));
                callback()
            })
            .catch((error) => {
                dispatch(fetchStylistTipsFailure(error));
            });
    };
};
export const CLOSE_STYLIST_TIPS_ERROR_REDUCER = () => {
    return (dispatch) => {
        dispatch(fetchStylistTipsFailure(''));
    };
};

export default StylistTipsReducer;
