import { CONTACT_SHOPS_API, CONTACT_API } from '../constants/api';
import {  GET_PROXY, POST } from '../functions/superagentSending';

const initialState = {
    loading: true,
    formLoading: false,
    meta: {},
    data: {},
    error: '',
};

const FETCH_CONTACT_REQUEST = 'FETCH_CONTACT_REQUEST';
const FETCH_CONTACT_SUCCESS = 'FETCH_CONTACT_SUCCESS';
const FETCH_CONTACT_FAILURE = 'FETCH_CONTACT_FAILURE';
const SEND_CONTACT_SUCCESS = 'SEND_CONTACT_SUCCESS';
const SEND_CONTACT_REQUEST = 'SEND_CONTACT_REQUEST';

const sendContactRequest = () => ({
    type: SEND_CONTACT_REQUEST,
});

const sendContactSuccess = (data) => ({
    type: SEND_CONTACT_SUCCESS,
    payload: data,
});
const fetchContactRequest = () => ({
    type: FETCH_CONTACT_REQUEST,
});

const fetchContactSuccess = (data) => ({
    type: FETCH_CONTACT_SUCCESS,
    payload: data,
});

const fetchContactFailure = (error) => ({
    type: FETCH_CONTACT_FAILURE,
    payload: error,
});

const ContactReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_CONTACT_REQUEST:
            return {
                ...state,
                loading: true,
                formLoading: false,
            };
        case SEND_CONTACT_REQUEST:
            return {
                ...state,
                loading: false,
                formLoading: true,
            };
        case SEND_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                formLoading: false,
            };
        case FETCH_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                formLoading: false,
                data: {
                    ...state?.data,
                    ...payload
                },
                error: '',
            };
        case FETCH_CONTACT_FAILURE:
            return {
                ...state,
                loading: false,
                formLoading: false,
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_CONTACT_SHOPS_REDUCER = (name) => {
    return (dispatch) => {
        dispatch(fetchContactRequest());
        GET_PROXY(`${CONTACT_SHOPS_API}/${name}`, 
        (success) => {
            dispatch(fetchContactSuccess(success?.data));
            }, (error) => {
            })
    };
};

export const FETCH_CONTACT_REDUCER = () => {
    return (dispatch) => {
        dispatch(fetchContactRequest());
        GET_PROXY(CONTACT_API, (success) => {
            dispatch(fetchContactSuccess(success?.data));
        }, (error) => {
            dispatch(fetchContactFailure(error));
            dispatch(fetchContactFailure(''));
        })
    };
};
export const SEND_CONTACT_REDUCER = (body, callback = () => { }) => {
    return (dispatch) => {
        dispatch(sendContactRequest());
        POST(CONTACT_API, body)
            .then((success) => {
                dispatch(sendContactSuccess(success?.body));
                callback()
            })
            .catch((error) => {
                dispatch(fetchContactFailure(error));
                // dispatch(fetchContactFailure(''));
            });
    };
};
export const CLOSE_CONTACT_ERROR_REDUCER = () => {
    return (dispatch) => {
        dispatch(fetchContactFailure(''));
    };
};

export default ContactReducer;
