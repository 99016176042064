export const USER_COM_KEY = "debaaw";

export const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://www.galaxy-centrum.pl"
    : "https://www.galaxy-centrum.pl";
export const API_MOBILE_URL = "https://galaxyapp.galaxy-centrum.pl";
export const API_USER_COM_URL = "https://galaxyapp.user.com/api/public";

export const TIME_FORMAT = "HH:mm";
export const DATE_FORMAT = "DD/MM/YYYY";

export const WORK_TIME_ID = "workTime";
export const PHONE_NUMBER = "+48914839130";

// LINKS

export const FASHION_BLOG_LINK = "https://blog.galaxy-centrum.pl/";
export const SOFINE_LINK = "https://sofine.pl/";
export const JOURNAL_LINK = "https://www.galaxy-centrum.pl/inspiracje9/mobile/";
export const GOOGLE_MAP_LINK = "https://goo.gl/maps/AwvPTkc1P1UJr4n86";
export const INSTAGRAM_LINK = "https://www.instagram.com/centrumgalaxy/";
export const FACEBOOK_LINK = "https://www.facebook.com/GalaxyCentrum/";
export const YOUTUBE_LINK = "https://www.youtube.com/user/StrefaFilmowa";
export const PARENT_COMPANY_LINK = "https://www.epp-poland.com";
export const GUARD_SECURITY_LINK =
  "https://impel.pl/uslugi/centra-i-sieci-handlowe/";
export const PAKAR_SERVICE_LINK = "https://pakar-service.pl/";



export const CHECK_CARD_LINK = 'https://finpay.pl/stansrodkow/'
export const CHECK_CARD_LINK_SECONDARY = 'https://secure.emlconnect.com/Account/Login?ReturnUrl=%2FCards%2FIndex'


export const RENTAL_PAGE_LINK = "https://help.epp-poland.com/";
export const FOR_COMPANIES_LINK = 'https://galaxy-centrum.finpay.pl/rejestracja';
export const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=pl.sofine.galaxyApp";
export const APP_STORE_LINK = "https://apps.apple.com/app/id1626258014";

export const HORIZONTAL_CARD = "1";

export const XSS_MOBILE_MEDIA = "@media (max-width: 450px)";

export const RECAPTCHA_ACTION = "ajax_form";

// PLAN
export const PREFIX_PLAN = "";
// export const PREFIX_PLAN = 'prefix__';
export const START_BOX_ID = `[id^='${PREFIX_PLAN}box_']`;

// REGEX

// eslint-disable-next-line
export const PHONE_REGEX =
  /^[\+]?[(]?[0-9]{0,3}[)]?[" "]?[-\s\.]?[0-9]{3}[" "]?[-\s\.]?[0-9]{3}[" "]?[-\s\.]?[0-9]{3,4}$/im;

// ERROR CODES

export const EMAIL_EXIST = 409;

export const DEFAULT_SCALE = 5;

export const APP_URL = 'galaxyapp://';
