import React, { useRef } from 'react'
// Typography, Container
import { makeStyles, useTheme, useMediaQuery, Box, } from '@material-ui/core';
import PropTypes from 'prop-types';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import LinkBtnComponent from '../../../components/LinkBtnComponent';
import DotsComponent from './DotsComponent';
import LinkComponent from '../../../components/LinkComponent';
import { Skeleton } from '@material-ui/lab';

const sliderHeight = 'auto';
const sliderHeightXs = 320;
const useStyles = makeStyles((theme) => ({
    slide: {
        height: sliderHeight,
        width: '100%',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: "contain",
        // position: "relative",
        // [theme.breakpoints.down('xs')]: {
        //     height: sliderHeightXs,
        // },
    },
    contentContainer: {
        display: 'flex',
        alignItems: 'center',
        height: sliderHeight,
        [theme.breakpoints.down('xs')]: {
            height: sliderHeightXs,
        },
    },
    content: {
        background: theme.palette.background.white,
        width: 450,
        padding: 40,
        // position: 'absolute',
        top: 0,
        bottom: 0,
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            padding: 20,
        },
    },
    contentText: {
        textTransform: 'inherit',
        marginBottom: 20,
    },
    title: {
        marginBottom: 10,
    },
    img: {
        width: '100%',
        height: sliderHeight,
        objectFit: 'contain',
        // [theme.breakpoints.down('xs')]: {
        //     // objectFit: 'cover',
        //     height: sliderHeightXs,
        // },
    },
    slider: {
        background: theme.palette.background.sliderBackground,
        '& .slick-slide': {
            pointerEvents: 'none',
            visibility: 'hidden',
            // [theme.breakpoints.down('xs')]: {
            //     height: sliderHeightXs,
            // },
        },
        '& .slick-slide.slick-active': {
            pointerEvents: 'auto',
            visibility: 'visible'
        },
    },
    skeletonDot: {
        maxWidth: 400,
        width: '90%',
        margin: 'auto',
        height: 49,
        position: 'relative',
    },
    skeletonBox: {
        background: theme.palette.background.gray
    },
    skeleton: {
        width: '100%',
        height: 720,
        [theme.breakpoints.down('xs')]: {
            height: sliderHeightXs,
        },
    }
}))



const MainSliderComponent = ({ data, loading }) => {
    const classes = useStyles();
    const sliderRef = useRef();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down(768))
    const settings = {
        dots: true,
        infinite: true,
        fade: true,
        arrows: false,
        autoplay: true,
        lazyLoad: true,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: (dots) => (
            <DotsComponent dots={dots} sliderRef={sliderRef} />
        ),
    };
    return (
        <section>
            <Slider {...settings} ref={sliderRef} className={classes.slider}>
                {loading
                    ?
                    <Box className={classes.skeletonBox}>
                        <Skeleton
                            variant='rect'
                            className={classes.skeleton}
                        />

                        <Skeleton
                            variant='rect'
                            className={classes.skeletonDot}
                        />
                    </Box>
                    : data?.length
                        ? data.map((item, index) => (
                            <div data-index={index} key={index}>
                                <LinkComponent
                                    to={item.to}
                                    key={index}
                                    title={item?.name}
                                    external_link={item.external_link}
                                >
                                    <div className={classes.slide} >
                                        <img
                                            // style={{ height: matches ? 320 : 720}}
                                            // height={matches ? 320 : 720}
                                            src={matches ? item.img_mobile_src : item.img_src}
                                            alt={item?.name}
                                            title={item?.name}
                                            className={classes.img}
                                        />
                                        {/* <Container className={classes.contentContainer}>
                                    <div className={classes.content}>
                                        <Typography variant='h3' className={classes.title}>
                                            {item.name}
                                        </Typography>
                                        <Typography
                                            variant='h4'
                                            className={classes.contentText}
                                        >
                                            {item.content}
                                        </Typography>
                                        <LinkBtnComponent
                                            to={item.to}
                                            external_link={item.external_link}
                                        />
                                    </div>
                                </Container> */}
                                    </div>
                                </LinkComponent>
                            </div>
                        ))
                        : null
                }
            </Slider>
        </section>
    )
}


MainSliderComponent.propTypes = {
    data: PropTypes.array,
}
export default React.memo(MainSliderComponent);