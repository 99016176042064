import React from 'react'
import PropTypes from 'prop-types';
import LinkComponent from './LinkComponent';
import ButtonComponent from './form/ButtonComponent';
import { useTranslation } from 'react-i18next';


const LinkBtnComponent = ({ className = '', white, download, title, to, external_link, secondary}) => {
    const {t} = useTranslation();
    return (
        <LinkComponent
            external_link={external_link}
            to={to}
            download={download}
            className={className}
            title={title || t('btns.readMore')}
        >
            <ButtonComponent
                secondary={secondary}
                white={white}
                title={title || t('btns.readMore')}
            />
        </LinkComponent>
    )
}


LinkBtnComponent.propTypes = {
    white: PropTypes.bool,
    secondary: PropTypes.bool,
    external_link: PropTypes.bool,
    download: PropTypes.bool,
    title: PropTypes.string,
    className: PropTypes.string,
    to: PropTypes.string,
}
export default React.memo(LinkBtnComponent);