import React from "react";
import { createRoot } from "react-dom/client";
import "./App.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./reducers";
import i18n from "./translation/i18n";
import { I18nextProvider } from "react-i18next";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <App />
    </Provider>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// reportWebVitals(console.log);
