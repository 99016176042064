export const classNames = (classes) => classes.map((cl) => `${cl}`).join(' ')
export const removeSpacing = (str) => str.replace(/\s+/g, '')
export const updateTime = (updateDate) => updateDate && parseInt(updateDate) + (1000 * 60 * 60) > new Date().getTime()
export const capitalizeText = (text) => text ? (text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()) : '';

export const getAndroidVersion = (isTop) => {
	const ua = navigator.userAgent;
	if (ua.indexOf("Android") >= 0) {
		const androidVersion = parseFloat(ua.slice(ua.indexOf("Android") + 8));
		return androidVersion
	} else {
		return false;
	}
};

export const getIOSversion = () => {
	if (/iP(hone|od|ad)/.test(navigator.platform)) {
		const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
		return `${parseInt(v[1], 10)}.${parseInt(v[2], 10)}.${parseInt(v[3] || 0, 10)}`;
	}
};

export const getParam = (paramName, search) => {
	const params = new URLSearchParams(search);
	return params.get(paramName);
};

export const makeid = (length) => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() *
			charactersLength));
	}
	return result;
}

const saveSvg = (svgEl, name) => {
	const svgBlob = new Blob([svgEl], { type: "image/svg+xml;charset=utf-8" });
	const svgUrl = URL.createObjectURL(svgBlob);
	const downloadLink = document.createElement("a");
	downloadLink.href = svgUrl;
	downloadLink.download = name;
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
}

// qrcode
// https://www.convertsimple.com/convert-csv-to-javascript-array/
// https://www.galaxy-centrum.pl/aplikacja?utm_source=galaxy&utm_medium=qrkod&utm_campaign=galaxyapp&utm_id=startapp&shopCode=95&type=

// const shops = [
// 	{
// 		name: "Prezent Marzeń",
// 		id: 218,
// 	},
// 	{
// 		name: "Insta Nails",
// 		id: 217
// 	},
// ];
// generateShopsQr(shops, QrCode);
export const generateShopsQr = (shops, QRCode = {
	toString: () => {}
}) => {
	shops.forEach(({ url, name, id }) => {
		QRCode.toString(`https://www.galaxy-centrum.pl/aplikacja?utm_source=galaxy&utm_medium=qrkod&utm_campaign=galaxyapp&utm_id=startapp&shopCode=${id}`, function (err, string) {
		// QRCode.toString(url, function (err, string) {
			if (err) throw err;
			saveSvg(string, `${id}_${name}.svg`)
			console.log(string)
		})
	})
}