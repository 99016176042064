import { PROMOTIONS_API } from '../constants/api';
import {  GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    data: {   },
    error: '',
};

const FETCH_PROMOTION_REQUEST = 'FETCH_PROMOTION_REQUEST';
const FETCH_PROMOTION_SUCCESS = 'FETCH_PROMOTION_SUCCESS';
const FETCH_PROMOTION_FAILURE = 'FETCH_PROMOTION_FAILURE';

const fetchPromotionRequest = () => ({
    type: FETCH_PROMOTION_REQUEST,
});

const fetchPromotionSuccess = (data) => ({
    type: FETCH_PROMOTION_SUCCESS,
    payload: data,
});

const fetchPromotionFailure = (error) => ({
    type: FETCH_PROMOTION_FAILURE,
    payload: error,
});

const PromotionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_PROMOTION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_PROMOTION_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                error: '',
            };
        case FETCH_PROMOTION_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_PROMOTION_REDUCER = (slug) => {
    return (dispatch) => {
        dispatch(fetchPromotionRequest());
        GET_PROXY(`${PROMOTIONS_API}/${slug}`,
            (success) => {
                dispatch(fetchPromotionSuccess(success?.data));
            },
            (error) => {
                dispatch(fetchPromotionFailure(error));
                dispatch(fetchPromotionFailure(''));
            })
    };
};

export default PromotionReducer;
