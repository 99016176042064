import { NEWS_API } from '../constants/api';
import { GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    postLoading: false,
    meta: {},
    data: [],
    error: '',
};

const FETCH_NEWS_REQUEST = 'FETCH_NEWS_REQUEST';
const FETCH_NEWS_POST_REQUEST = 'FETCH_NEWS_POST_REQUEST';
const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
const FETCH_NEWS_FAILURE = 'FETCH_NEWS_FAILURE';

const fetchNewsRequest = () => ({
    type: FETCH_NEWS_REQUEST,
});
const fetchNewsPostRequest = () => ({
    type: FETCH_NEWS_POST_REQUEST,
});

const fetchNewsSuccess = (data) => ({
    type: FETCH_NEWS_SUCCESS,
    payload: data,
});

const fetchNewsFailure = (error) => ({
    type: FETCH_NEWS_FAILURE,
    payload: error,
});

const NewsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_NEWS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_NEWS_POST_REQUEST:
            return {
                ...state,
                postLoading: true,
            };
        case FETCH_NEWS_SUCCESS:
            return {
                ...state,
                loading: false,
                postLoading: false,
                data: [
                    ...(payload?.meta?.current_page > 1 ? state?.data : []),
                    ...payload?.data
                ],
                meta: payload.meta,
                error: '',
            };
        case FETCH_NEWS_FAILURE:
            return {
                meta: {},
                loading: false,
                postLoading: false,
                data: {},
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_NEWS_REDUCER = (page) => {
    return (dispatch) => {
        if (page)
            dispatch(fetchNewsPostRequest());
        else
            dispatch(fetchNewsRequest());
        GET_PROXY(`${NEWS_API}?page=${page}`,
            (success) => {
                dispatch(fetchNewsSuccess(success));
            },
            (error) => {
                dispatch(fetchNewsFailure(error));
                dispatch(fetchNewsFailure(''));
            })
    };
};

export default NewsReducer;
