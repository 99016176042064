import { GALLERY_API } from '../constants/api';
import { GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    postLoading: false,
    meta: {},
    data: {},
    error: '',
};

const FETCH_GALLERY_REQUEST = 'FETCH_GALLERY_REQUEST';
const FETCH_GALLERY_POST_REQUEST = 'FETCH_GALLERY_POST_REQUEST';
const FETCH_GALLERY_SUCCESS = 'FETCH_GALLERY_SUCCESS';
const FETCH_GALLERY_FAILURE = 'FETCH_GALLERY_FAILURE';

const fetchGalleryRequest = () => ({
    type: FETCH_GALLERY_REQUEST,
});
const fetchGalleryPostRequest = () => ({
    type: FETCH_GALLERY_POST_REQUEST,
});

const fetchGallerySuccess = (data) => ({
    type: FETCH_GALLERY_SUCCESS,
    payload: data,
});

const fetchGalleryFailure = (error) => ({
    type: FETCH_GALLERY_FAILURE,
    payload: error,
});

const GalleryReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_GALLERY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_GALLERY_POST_REQUEST:
            return {
                ...state,
                postLoading: true,
            };
        case FETCH_GALLERY_SUCCESS:
            return {
                ...state,
                loading: false,
                postLoading: false,
                data: {
                    ...state.data,
                    ...payload.data,
                    gallery: [
                        ...(payload?.meta?.current_page > 1 ? state?.data.gallery : []),
                        ...payload?.data?.gallery,
                    ]
                },
                meta: payload.meta,
                error: '',
            };
        case FETCH_GALLERY_FAILURE:
            return {
                meta: {},
                loading: false,
                postLoading: false,
                data: {},
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_GALLERY_REDUCER = (slug, page) => {
    return (dispatch) => {
        if (page)
            dispatch(fetchGalleryPostRequest());
        else
            dispatch(fetchGalleryRequest());
        GET_PROXY(`${GALLERY_API}/${slug}?page=${page}`,
            (success) => {
                dispatch(fetchGallerySuccess(success));
            }, (error) => {
                dispatch(fetchGalleryFailure(error));
                dispatch(fetchGalleryFailure(''));
            })
    };
};

export default GalleryReducer;
