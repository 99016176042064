import { MAIN_API } from '../constants/api';
import { GET_PROXY } from '../functions/superagentSending';

const HOME_ENDPOINT = 'HOME_ENDPOINT'

const initialState = {
	loading: true,
	data: {},
	error: '',
};

const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';

const fetchDataRequest = () => ({
	type: FETCH_DATA_REQUEST,
});

const fetchDataSuccess = (data) => ({
	type: FETCH_DATA_SUCCESS,
	payload: data,
});

const fetchDataFailure = (error) => ({
	type: FETCH_DATA_FAILURE,
	payload: error,
});

const HomePageReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_DATA_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
				error: '',
			};
		case FETCH_DATA_FAILURE:
			return {
				loading: false,
				data: {},
				error: payload,
			};
		default:
			return state;
	}
};

export const FETCH_HOME_DATA_REDUCER = () => {
	return (dispatch) => {
		dispatch(fetchDataRequest());
		const ifConnected = window.navigator.onLine;
		if (ifConnected) {
			GET_PROXY(MAIN_API, (success) => {
				const data = success?.data
				localStorage.setItem(HOME_ENDPOINT, JSON.stringify(data))
				dispatch(fetchDataSuccess(data));
			}, (error) => {
				dispatch(fetchDataFailure(error));
				dispatch(fetchDataFailure(''));
			})
		} else {
			const data = localStorage.getItem(HOME_ENDPOINT) ? JSON.parse(localStorage.getItem(HOME_ENDPOINT)) : false;
			if (data)
				dispatch(fetchDataSuccess(data));
		}

	};
};

export default HomePageReducer;
