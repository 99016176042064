import React from 'react'
import {  IconButton, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles((theme) => ({
    hamburger: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
        width: 40,
        height: 40,
        color: theme.palette.text.primary
      
    },
    activeHamburger: {
        width: 40,
        height: 40,
        color: theme.palette.text.white
    }
}))

const HamburgerBtnComponent = ({ toggleShow = () => { }, show }) => {
    const classes = useStyles();

    return (
        <IconButton onClick={toggleShow}>
            {show
                ? <CloseIcon
                    className={classes.activeHamburger}
                  
                />
                :
                <MenuIcon
                    className={classes.hamburger}
                />
            }
        </IconButton>
    )
}


HamburgerBtnComponent.propTypes = {
    show: PropTypes.bool,
    toggleShow: PropTypes.func,
}
export default React.memo(HamburgerBtnComponent);