import { PROMOTIONS_API } from '../constants/api';
import {  GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    postLoading: false,
    meta: {},
    data: [],
    error: '',
};

const FETCH_PROMOTIONS_REQUEST = 'FETCH_PROMOTIONS_REQUEST';
const FETCH_PROMOTIONS_SUCCESS = 'FETCH_PROMOTIONS_SUCCESS';
const FETCH_PROMOTIONS_POST_REQUEST = 'FETCH_PROMOTIONS_POST_REQUEST';
const FETCH_PROMOTIONS_FAILURE = 'FETCH_PROMOTIONS_FAILURE';

const fetchPromotionsRequest = () => ({
    type: FETCH_PROMOTIONS_REQUEST,
});

const fetchPromotionsSuccess = (data) => ({
    type: FETCH_PROMOTIONS_SUCCESS,
    payload: data,
});

const fetchPromotionsPostRequest = () => ({
    type: FETCH_PROMOTIONS_POST_REQUEST,
});


const fetchPromotionsFailure = (error) => ({
    type: FETCH_PROMOTIONS_FAILURE,
    payload: error,
});

const PromotionsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_PROMOTIONS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_PROMOTIONS_POST_REQUEST:
            return {
                ...state,
                postLoading: true,
            };
        case FETCH_PROMOTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                postLoading: false,
                data: [
                    
                    ...(payload?.meta?.current_page > 1 ? state?.data : []),
                    ...(payload?.data || []),
                ],
                meta: payload.meta,
                error: '',
            };
        case FETCH_PROMOTIONS_FAILURE:
            return {
                meta: {},
                loading: false,
                postLoading: false,
                data: [],
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_PROMOTIONS_REDUCER = ( page) => {
    return (dispatch) => {
        if (page)
            dispatch(fetchPromotionsPostRequest());
        else
            dispatch(fetchPromotionsRequest());
        GET_PROXY(`${PROMOTIONS_API}?page=${page}`,
            (success) => {
                dispatch(fetchPromotionsSuccess(success));
            },
            (error) => {
                dispatch(fetchPromotionsFailure(error));
                dispatch(fetchPromotionsFailure(''));
            })
    };
};

export default PromotionsReducer;
