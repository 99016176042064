import React from 'react'
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
// import CardLinkComponent from './CardLinkComponent';
import SquarePaperSkeletonComponent from './skeletons/SquarePaperSkeletonComponent';
import loadable from '@loadable/component'

const CardLinkComponent = loadable(() => import('./CardLinkComponent'))

const CardsComponent = ({ data, loading, sm = 6, md = 3, xs = 6, slug }) => {
    return (
        <Grid container >
            {loading
                ? <>
                    <SquarePaperSkeletonComponent
                        sm={sm}
                        md={md}
                        xs={xs}
                    />
                    <SquarePaperSkeletonComponent
                        sm={sm}
                        md={md}
                        xs={xs}
                    />
                    <SquarePaperSkeletonComponent
                        sm={sm}
                        md={md}
                        xs={xs}
                    />
                    <SquarePaperSkeletonComponent
                        sm={sm}
                        md={md}
                        xs={xs}
                    />
                </>
                : data?.length
                    ? data.map((item, key) =>
                        <CardLinkComponent
                            sm={sm}
                            md={md}
                            xs={xs}
                            Icon={item.icon}
                            src={item.src}
                            name={item.name}
                            color={item.color}
                            showName={item.showName}
                            paperClassName={item.paperClassName}
                            to={!item.withoutSlug && slug ? `${slug}/${item.slug}` : item.to}
                            type={item.type}
                            key={key}
                        />
                    )
                    : null}
        </Grid>
    )
}


CardsComponent.propTypes = {
    md: PropTypes.number,
    sm: PropTypes.number,
    xs: PropTypes.number,
    data: PropTypes.array,
    slug: PropTypes.string,
    loading: PropTypes.bool,
}
export default React.memo(CardsComponent);