import { SERVICES_API } from '../constants/api';
import {  GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    data: {},
    error: '',
};

const FETCH_SERVICE_REQUEST = 'FETCH_SERVICE_REQUEST';
const FETCH_SERVICE_SUCCESS = 'FETCH_SERVICE_SUCCESS';
const FETCH_SERVICE_FAILURE = 'FETCH_SERVICE_FAILURE';

const fetchServiceRequest = () => ({
    type: FETCH_SERVICE_REQUEST,
});

const fetchServiceSuccess = (data) => ({
    type: FETCH_SERVICE_SUCCESS,
    payload: data,
});

const fetchServiceFailure = (error) => ({
    type: FETCH_SERVICE_FAILURE,
    payload: error,
});

const ServiceReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_SERVICE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                error: '',
            };
        case FETCH_SERVICE_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_SERVICE_REDUCER = (slug) => {
    return (dispatch) => {
        dispatch(fetchServiceRequest());
        GET_PROXY(`${SERVICES_API}/${slug}`,
            (success) => {
                dispatch(fetchServiceSuccess(success?.data));
            },
            (error) => {
                dispatch(fetchServiceFailure(error));
                dispatch(fetchServiceFailure(''));
            })
    };
};

export default ServiceReducer;
