import { SHOPS_API } from '../constants/api';
import { GET_PROXY } from '../functions/superagentSending';

const SHOPS_ENDPOINT = 'SHOPS_ENDPOINT'
const initialState = {
    loading: true,
    postLoading: false,
    categoryLoading: false,
    meta: {},
    data: {},
    error: '',
};

const FETCH_SHOPS_REQUEST = 'FETCH_SHOPS_REQUEST';
const FETCH_SHOPS_POST_REQUEST = 'FETCH_SHOPS_POST_REQUEST';
const FETCH_SHOPS_CATEGORY_REQUEST = 'FETCH_SHOPS_CATEGORY_REQUEST';
const FETCH_SHOPS_SUCCESS = 'FETCH_SHOPS_SUCCESS';
const FETCH_SHOPS_FAILURE = 'FETCH_SHOPS_FAILURE';

const fetchShopsRequest = () => ({
    type: FETCH_SHOPS_REQUEST,
});
const fetchShopsPostRequest = () => ({
    type: FETCH_SHOPS_POST_REQUEST,
});
const fetchShopsCategoryRequest = () => ({
    type: FETCH_SHOPS_CATEGORY_REQUEST,
});

const fetchShopsSuccess = (data) => ({
    type: FETCH_SHOPS_SUCCESS,
    payload: data,
});

const fetchShopsFailure = (error) => ({
    type: FETCH_SHOPS_FAILURE,
    payload: error,
});

const ShopsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_SHOPS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_SHOPS_POST_REQUEST:
            return {
                ...state,
                postLoading: true,
            };
        case FETCH_SHOPS_CATEGORY_REQUEST:
            return {
                ...state,
                categoryLoading: true,
            };
        case FETCH_SHOPS_SUCCESS:
            return {
                ...state,
                loading: false,
                postLoading: false,
                categoryLoading: false,
                data: {
                    ...state.data,
                    ...payload?.data,
                    shops: [
                        ...(payload?.meta?.current_page > 1 ? state?.data?.shops : []),
                        ...(payload?.data?.shops || []),
                    ]
                },
                meta: payload.meta,
                error: '',
            };
        case FETCH_SHOPS_FAILURE:
            return {
                meta: {},
                loading: false,
                postLoading: false,
                categoryLoading: false,
                data: {},
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_SHOPS_REDUCER = (name = '', categories = '', promotions = '', lettersFilter = '', page = '', callback = () => { }) => {
    return (dispatch) => {
        if (page && page === 1)
            dispatch(fetchShopsCategoryRequest())
        else if (page && page > 1)
            dispatch(fetchShopsPostRequest());
        else
            dispatch(fetchShopsRequest());

        const ifConnected = window.navigator.onLine;
        if (ifConnected) {
            GET_PROXY(`${SHOPS_API}?page=${page}&filter[categories]=${categories}&filter[lettersFilter]=${lettersFilter}&filter[promotions]=${promotions}&filter[name]=${name}`,
                (success) => {
                    const body = success;
                    if (!categories?.length && !lettersFilter?.length && !promotions && !name)
                        if (page > 1) {
                            const oldCache = localStorage.getItem(SHOPS_ENDPOINT) ? JSON.parse(localStorage.getItem(SHOPS_ENDPOINT)) : {};
                            const cacheData = {
                                ...oldCache,
                                data: {
                                    categories: oldCache?.data?.categories,
                                    shops: [
                                        ...(oldCache?.data?.shops?.length ? oldCache.data.shops : []),
                                        ...(body?.data?.shops?.length ? body.data.shops : []),
                                    ]
                                }
                            }
                            localStorage.setItem(SHOPS_ENDPOINT, JSON.stringify(cacheData));
                        } else {
                            localStorage.setItem(SHOPS_ENDPOINT, JSON.stringify(body));
                        }
                    callback()
                    dispatch(fetchShopsSuccess(body));
                },
                (error) => {
                    dispatch(fetchShopsFailure(error));
                    dispatch(fetchShopsFailure(''));
                })
        } else {
            const data = localStorage.getItem(SHOPS_ENDPOINT) ? JSON.parse(localStorage.getItem(SHOPS_ENDPOINT)) : false;
            if (data) {
                dispatch(fetchShopsSuccess(data));
            }
        }
    };
};

export default ShopsReducer;
