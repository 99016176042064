import React from 'react'
import { Container, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
// import WorkTimeItemComponent from './WorkTimeItemComponent';
import ArrowSvg from '../../svgs/ArrowSvg';
import { INFORMATION_ROUTE } from '../../constants/routes';
import LinkComponent from '../LinkComponent';
import { ARROW_ANIMATION_HOVER, BLUR_EFFECT } from '../../constants/csses';
import { useSelector } from 'react-redux';
import { WORK_TIME_ID } from '../../constants';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import loadable from '@loadable/component'

const WorkTimeItemComponent = loadable(() => import('./WorkTimeItemComponent'))

const useStyles = makeStyles((theme) => ({
    cont: {
        display: 'flex',
        alignItems: 'center',
    },
    skeleton: {
        minHeight: 20,
        [theme.breakpoints.down('xs')]: {
            minHeight: 35,
        },
        '@media (max-width: 380px)': {
            minHeight: 51,
        },
    },
    workTimeCont: {
        padding: 10,
        ...BLUR_EFFECT,
        '&:hover': {
            ...ARROW_ANIMATION_HOVER
        },
        [theme.breakpoints.down('xs')]: {
            '& .arrow-svg': {
                display: 'none',
            }
        }
    },
}))

const HeaderWorkTimeComponent = (props) => {
    const classes = useStyles();
    const { data } = useSelector((state) => state.MainReducer)
    const {t} = useTranslation();
    return (
        <section className={classes.workTimeCont}>
            {/* {false */}
            {data?.workTime?.length
                ?
                <LinkComponent to={`${INFORMATION_ROUTE}#${WORK_TIME_ID}`} className={classes.link} title={t('place.work_time_title')}>
                    <Container className={classes.cont}>
                        {data.workTime.map((item, key) =>
                            <WorkTimeItemComponent
                                key={key}
                                first={key === 0}
                                title={item.title}
                                startTime={item.startTime}
                                finishTime={item.finishTime}
                                value={item.value}
                                last={key === (data.workTime.length - 1)}
                            />
                        )
                        }
                        <ArrowSvg className={classes.arrow} />
                    </Container>
                </LinkComponent>
                : <Skeleton className={classes.skeleton} variant='rect'></Skeleton>}
        </section>
    )
}


HeaderWorkTimeComponent.propTypes = {
    name: PropTypes.string,
}
export default React.memo(HeaderWorkTimeComponent);