import { CAREER_API } from '../constants/api';
import {  GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    data: {},
    error: '',
};

const FETCH_CAREER_OFFER_REQUEST = 'FETCH_CAREER_OFFER_REQUEST';
const FETCH_CAREER_OFFER_SUCCESS = 'FETCH_CAREER_OFFER_SUCCESS';
const FETCH_CAREER_OFFER_FAILURE = 'FETCH_CAREER_OFFER_FAILURE';

const fetchCareerOfferRequest = () => ({
    type: FETCH_CAREER_OFFER_REQUEST,
});

const fetchCareerOfferSuccess = (data) => ({
    type: FETCH_CAREER_OFFER_SUCCESS,
    payload: data,
});

const fetchCareerOfferFailure = (error) => ({
    type: FETCH_CAREER_OFFER_FAILURE,
    payload: error,
});

const CareerOfferReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_CAREER_OFFER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_CAREER_OFFER_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                error: '',
            };
        case FETCH_CAREER_OFFER_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_CAREER_OFFER_REDUCER = (slug) => {
    return (dispatch) => {
        dispatch(fetchCareerOfferRequest());
        GET_PROXY(`${CAREER_API}/${slug}`, (success) => {
            dispatch(fetchCareerOfferSuccess(success?.data));
        }, (error) => {
            dispatch(fetchCareerOfferFailure(error));
            dispatch(fetchCareerOfferFailure(''));
        })

    };
};

export default CareerOfferReducer;
