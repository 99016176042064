import * as React from "react"

function YouTubeSvg(props) {
	return (
		<svg
			data-name="Warstwa 1"
			width={40}
			height={40}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 204.23 146.23"
			{...props}
		>
			<path d="M117.85 144.72c-19.22 0-54.18-.49-67.47-1.05a151.48 151.48 0 01-18.45-1.93C17.65 139.34 8.69 130.39 6 115.85c-2.12-11.77-2.64-23.72-3-34.94A296.31 296.31 0 014.43 42a109.51 109.51 0 012.23-14C9.77 14.47 20 5.67 34.08 4.43 49.8 3 62.69 2.24 74.62 1.92c18-.49 34.45-.54 50.27-.16 15.28.37 30.27 1.26 44.55 2.64 17 1.65 26.81 11.32 29 28.74 1.37 10.62 2 21.34 2.61 32.3.78 14.43-.31 28.44-1.35 39a111.48 111.48 0 01-2.22 14.06c-3.11 13.51-13.37 22.31-27.42 23.55-12.25 1.08-25.54 1.91-40.65 2.53-2.06.11-6.2.14-11.56.14zm-66.92-14.11c17.71.74 69.62 1.28 78 .93 14.91-.62 28-1.43 40-2.49 8.5-.75 14-5.41 15.83-13.46a96.69 96.69 0 001.95-12.4c1-10.13 2.05-23.49 1.31-37-.58-10.71-1.21-21.16-2.52-31.35-1.47-11.45-6.33-16.32-17.33-17.39-14-1.35-28.65-2.22-43.62-2.58C109 14.44 92.74 14.5 75 15c-11.66.31-24.29 1.09-39.74 2.46-8.5.75-14 5.41-15.83 13.45a96.06 96.06 0 00-2 12.4 286.55 286.55 0 00-1.37 37.2c.34 10.73.84 22.11 2.83 33 1.66 9.13 6.35 13.87 15.19 15.35a138.75 138.75 0 0016.84 1.76zm32.26-23.14a6.55 6.55 0 01-6.53-6.53V45.56a6.53 6.53 0 019.76-5.67l48.66 27.68a6.53 6.53 0 010 11.36l-48.66 27.68a6.54 6.54 0 01-3.23.86zm6.53-50.67v32.9l28.92-16.45z" />
		</svg>
	)
}

export default YouTubeSvg
