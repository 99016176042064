export const MENU_API = '/menu';
export const GASTRONOMY_API = '/gastronomy';
export const SHOPS_API = '/shop';
export const STYLIST_TIPS_API = '/stylist';
export const PROMOTIONS_API = '/promotion';
export const NEWS_API = '/news';
export const EVENTS_API = '/events';
export const CERTIFICATIONS_API = '/certification';
export const INFORMATION_API = '/information';
export const MAIN_API = '/main';
export const CONTACT_SHOPS_API = '/contact_shops'
export const CONTACT_API = '/contact'
export const PLAN_API = '/plan'
export const SALES_TABLE_API = '/sales_table'
export const CUSTOM_API = '/page'
export const REGULATION_API = '/regulation'
export const CAREER_API = '/career'
export const GALLERY_API = '/gallery'
export const ABOUT_API = '/about'
export const VIDEO_API = '/video'
export const SAFE_SHOPING_API = '/safe_shopping'
export const SERVICES_API = '/service'
export const SEARCH_API = '/search'
export const NEWSLETTER_API = '/newsletter'
export const SPECIALS_PROMOTIONS_API = '/special_promotion'
export const FASHION_INSPIRATION_API = '/fashion_inspiration'
export const QUESTIONNAIRE_API = '/questionnaire'
export const HELP_UKRAINE_API = '/pomoc-ukrainie'
export const CHECK_USER_CARD_API = '/employee/check_user_card'
export const CHECK_COMPETITION_API = '/employee/check_competition'
export const GIVE_USER_CARD_API = '/employee/give_rewards'
export const ADD_RESULT_API = '/employee/add_result'
export const RESET_PASSWORD_API = '/employee/reset-password'
export const CONFIRM_USER_API = '/email/verify'
export const NEW_DOWNLOAD_API = '/add_download'

export const USER_COM_SEARCH_USER_API = "/users/search"
export const USER_COM_UPDATE_USER_API = "/users"
