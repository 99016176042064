import { PLAN_API } from '../constants/api';
import {  GET_PROXY } from '../functions/superagentSending';

const PLAN_ENDPOINT = 'PLAN_ENDPOINT'
const initialState = {
    loading: true,
    data: [],
    error: '',
};

const FETCH_PLAN_REQUEST = 'FETCH_PLAN_REQUEST';
const FETCH_PLAN_SUCCESS = 'FETCH_PLAN_SUCCESS';
const FETCH_PLAN_FAILURE = 'FETCH_PLAN_FAILURE';

const fetchPlanRequest = () => ({
    type: FETCH_PLAN_REQUEST,
});

const fetchPlanSuccess = (data) => ({
    type: FETCH_PLAN_SUCCESS,
    payload: data,
});

const fetchPlanFailure = (error) => ({
    type: FETCH_PLAN_FAILURE,
    payload: error,
});

const PlanReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_PLAN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                error: '',
            };
        case FETCH_PLAN_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_PLAN_REDUCER = () => {
    return (dispatch) => {
        dispatch(fetchPlanRequest());
        const ifConnected = window.navigator.onLine;
        if (ifConnected) {
            GET_PROXY(PLAN_API,
                (success) => {
                    const data = success?.data
                    localStorage.setItem(PLAN_ENDPOINT, JSON.stringify(data))
                    dispatch(fetchPlanSuccess(data));
                },
                (error) => {
                    dispatch(fetchPlanFailure(error));
                    dispatch(fetchPlanFailure(''));
                })
        } else {
            const data = localStorage.getItem(PLAN_ENDPOINT) ? JSON.parse(localStorage.getItem(PLAN_ENDPOINT)) : false;
            if (data)
                dispatch(fetchPlanSuccess(data));
        }
    };
};

export default PlanReducer;
