import React from 'react'
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import ArrowSvg from '../../svgs/ArrowSvg';
import { ARROW_ANIMATION_HOVER } from '../../constants/csses';
import { classNames } from '../../functions';

const useStyles = (white, primary, disabled) => makeStyles((theme) => ({
    btn: {
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        transition: 'all 0.2s ease-in',
        pointerEvents: disabled ? 'none' : 'auto',
        filter: disabled ? 'grayscale(1)': 'none',
        '& .arrow-line': {
            background: white ? theme.palette.text.white : theme.palette.text.black,
        },
        '& path': {
            stroke: white ? theme.palette.text.white : theme.palette.text.black,
        },
        '&:hover': {
            ...ARROW_ANIMATION_HOVER,
            
            // '& .circle-btn': {
            //     right: 0,
            // }
        },
        [theme.breakpoints.down('xs')]: {
            '&:hover': {
                '& .arrow-line': {
                    width: '50px !important',
                }
             
            },
        }
    },
    btnAnimCont: {
        display: 'flex',
        alignItems: 'center',
        height: 60,
        position: 'relative',
        width: 80,
    },
    circle: {
        width: 50,
        transition: 'all 0.2s ease-in',
        height: 50,
        position: 'absolute',
        background: primary ? theme.palette.primary.main : theme.palette.secondary.main,
        borderRadius: '50%',
        left: 20,
        top: 4,
        right: 0,
        zIndex: 0,
        [theme.breakpoints.down('xs')]: {
            top: 9,
            width: 40,
            height: 40,
        },
    },
    title: {
        textTransform: 'uppercase',
        color: white ? theme.palette.text.white : theme.palette.text.black,
        fontWeight: 'bold',
        marginRight: 20,

    }
}))

const ButtonComponent = ({ disabled, title, onClick = () => { }, white, primary, className = '' }) => {
    const classes = useStyles(white, primary, disabled)();

    return (
        <section className={classNames([classes.btn, className])} onClick={onClick}>
            <Typography className={classes.title}>{title}</Typography>
            <div className={classes.btnAnimCont}>
                <ArrowSvg />
                <div className={classNames([classes.circle, 'circle-btn'])}></div>
            </div>
        </section>
    )
}


ButtonComponent.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
}
export default React.memo(ButtonComponent);
