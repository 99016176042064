import { makeStyles } from "@material-ui/core"
import * as React from "react"
import { classNames } from "../functions";

const useStyles = makeStyles((theme) => ({
    line: {
        width: 40,
        height: 2,
        marginRight: -13,
        background: '#000000',
        transition: 'all 0.2s ease-in',
    },
    arrow: {
        position:'relative',
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
    }
}))
function ArrowSvg(props) {
    const classes = useStyles();
    return (
        <div className={classNames([classes.arrow, 'arrow-svg'])}>
            <div className={classNames([classes.line, 'arrow-line'])}></div>
            <svg
                viewBox="0 0 15 15"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
                width='15'
                height='16'
                {...props}
            >
                <path
                    d="M6.368.788L13.08 7.5l-6.712 6.712"
                    fill="none"
                    stroke="#000000"
                    strokeWidth={2}
                />
            </svg>
        </div>
    )
}

export default ArrowSvg
