import React from 'react'
import { Backdrop, makeStyles, } from '@material-ui/core';
import PropTypes from 'prop-types';
import LoaderAnimationComponent from './LoaderAnimationComponent';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    loader: {
        width: 400,
    }
}))

const LoaderComponent = ({ open }) => {
    const classes = useStyles();


    return (
        <>
            <Backdrop open={open} className={classes.backdrop} >
                <LoaderAnimationComponent className={classes.loader} />
            </Backdrop>
        </>
    )
}


LoaderComponent.propTypes = {
    open: PropTypes.bool,
}
export default React.memo(LoaderComponent);