import * as React from "react"

function InstagramSvg(props) {
    return (
        <svg
            viewBox="0 0 33 33"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            width={30}
            height={30}
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <g fill="none" stroke="#000" strokeWidth={3}>
                <path d="M9 1.5h15c4.114 0 7.5 3.386 7.5 7.5v15c0 4.114-3.386 7.5-7.5 7.5H9c-4.114 0-7.5-3.386-7.5-7.5V9c0-4.114 3.386-7.5 7.5-7.5z" />
                <path d="M22.5 15.555c.043.291.065.586.065.88 0 3.292-2.709 6-6 6-3.292 0-6-2.708-6-6 0-3.291 2.708-6 6-6a6.015 6.015 0 015.935 5.12z" />
            </g>
        </svg>
    )
}

export default InstagramSvg
