import * as React from "react"

function HappyPortBackSvg(props) {
    return (
        <svg
            viewBox="0 0 2325 1386"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit={2}
            {...props}
        >
            <path
                d="M0 118.212S205.552-36.092 774.377 7.886c568.829 43.978 400.633 604.629 1448.809 196.263 1048.175-408.365 0 1398.4 0 1398.4H3.09L0 118.212z"
                fill="#f0f0f0"
                fillRule="nonzero"
            />
        </svg>
    )
}

export default HappyPortBackSvg
