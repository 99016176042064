import { SPECIALS_PROMOTIONS_API } from '../constants/api';
import {  GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    postLoading: false,
    meta: {},
    data: {},
    error: '',
};

const FETCH_SPECIALS_PROMOTIONS_REQUEST = 'FETCH_SPECIALS_PROMOTIONS_REQUEST';
const FETCH_SPECIALS_PROMOTIONS_SUCCESS = 'FETCH_SPECIALS_PROMOTIONS_SUCCESS';
const FETCH_SPECIALS_PROMOTIONS_POST_REQUEST = 'FETCH_SPECIALS_PROMOTIONS_POST_REQUEST';
const FETCH_SPECIALS_PROMOTIONS_FAILURE = 'FETCH_SPECIALS_PROMOTIONS_FAILURE';

const fetchSpecialsPromotionsRequest = () => ({
    type: FETCH_SPECIALS_PROMOTIONS_REQUEST,
});

const fetchSpecialsPromotionsSuccess = (data) => ({
    type: FETCH_SPECIALS_PROMOTIONS_SUCCESS,
    payload: data,
});

const fetchSpecialsPromotionsPostRequest = () => ({
    type: FETCH_SPECIALS_PROMOTIONS_POST_REQUEST,
});


const fetchSpecialsPromotionsFailure = (error) => ({
    type: FETCH_SPECIALS_PROMOTIONS_FAILURE,
    payload: error,
});

const SpecialsPromotionsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_SPECIALS_PROMOTIONS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_SPECIALS_PROMOTIONS_POST_REQUEST:
            return {
                ...state,
                postLoading: true,
            };
        case FETCH_SPECIALS_PROMOTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                postLoading: false,
                data: {
                    ...payload,
                    list: [
                        ...(payload?.meta?.current_page > 1 ? state?.list : []),
                        ...(payload?.list || []),
                    ]
                },
                meta: payload.meta,
                error: '',
            };
        case FETCH_SPECIALS_PROMOTIONS_FAILURE:
            return {
                meta: {},
                loading: false,
                postLoading: false,
                data: [],
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_SPECIALS_PROMOTIONS_REDUCER = (slug, page) => {
    return (dispatch) => {
        if (page)
            dispatch(fetchSpecialsPromotionsPostRequest());
        else
            dispatch(fetchSpecialsPromotionsRequest());
        GET_PROXY(`${SPECIALS_PROMOTIONS_API}/${slug}?page=${page}`,
            (success) => {
                dispatch(fetchSpecialsPromotionsSuccess(success?.data));
            },
            (error) => {
                dispatch(fetchSpecialsPromotionsFailure(error));
                dispatch(fetchSpecialsPromotionsFailure(''));
            })
    };
};

export default SpecialsPromotionsReducer;
