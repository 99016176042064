import React, { useEffect, useMemo, useRef } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import HeaderWorkTimeComponent from './HeaderWorkTimeComponent';
import AppBarComponent from './AppBarComponent';
import loadable from '@loadable/component';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_MENU_REDUCER } from '../../reducers/MainReducer';
import { useHistory } from 'react-router';
import { EMPTY_ROUTE } from '../../constants/routes';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { capitalizeText } from '../../functions';
import LazyLoad from 'react-lazyload';
import { USER_COM_KEY } from '../../constants';

const FooterComponent = loadable(() => import('./FooterComponent'));

const useStyles = makeStyles(theme => ({
  header: {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 10,
  },
  nav: {
    background: theme.palette.background.default,
  },
  main: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    marginTop: 124,
    [theme.breakpoints.down(960)]: {
      marginTop: 148
    },
    [theme.breakpoints.down(742)]: {
      marginTop: 168
    },
    [theme.breakpoints.down(600)]: {
      marginTop: 160
    }
  },
}));

const MainContainerComponent = ({
  disableTop,
  children,
  hiddenMap,
  hiddenCovid,
  title,
}) => {
  const classes = useStyles();
  const { data } = useSelector(state => state.MainReducer);
  const state = useSelector(state => state);
  const history = useHistory();
  const { t } = useTranslation();
  const header = useRef();
  const metaTitle = useMemo(
    () => `${title ? `${capitalizeText(title)} -` : ''} ${t('meta_title')}`,
    [title, t],
  );
  const errorsArray = useMemo(
    () =>
      Object.keys(state)
        .filter(key => state[key].error)
        .map(key => state[key].error),
    [state],
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!disableTop) window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [history, window.location.href, disableTop]);

  useEffect(() => {
    if (!data?.menu) {
      dispatch(FETCH_MENU_REDUCER());
    }
  }, [dispatch, data]);

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'Pageview',
        page: {
          title: metaTitle,
        },
      });
    }
    if (window.UE) {
      window.UE.pageHit({
        apiKey: USER_COM_KEY,
        status: '1',
      });
    }
  }, [metaTitle]);
  useEffect(() => {
    if (
      errorsArray?.length &&
      errorsArray.find(error => error.message === '404')
    )
      history.push(EMPTY_ROUTE);
    // eslint-disable-next-line
  }, [errorsArray]);

  return (
    <section>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <header className={classes.header} ref={header}>
        <HeaderWorkTimeComponent />
        <AppBarComponent />
      </header>
      <Box
        className={classes.main}
        component='main'
        // marginTop={`${header?.current?.offsetHeight || 158}px`}
      >
        {children}
      </Box>
      <LazyLoad height={550}>
        <FooterComponent hiddenMap={hiddenMap} hiddenCovid={hiddenCovid} />
      </LazyLoad>
    </section>
  );
};

MainContainerComponent.propTypes = {
  name: PropTypes.string,
  hiddenMap: PropTypes.bool,
};
export default React.memo(MainContainerComponent);
