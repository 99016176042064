import { CERTIFICATIONS_API } from '../constants/api';
import {  GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    data: [   ],
    error: '',
};

const FETCH_CERTIFICATIONS_REQUEST = 'FETCH_CERTIFICATIONS_REQUEST';
const FETCH_CERTIFICATIONS_SUCCESS = 'FETCH_CERTIFICATIONS_SUCCESS';
const FETCH_CERTIFICATIONS_FAILURE = 'FETCH_CERTIFICATIONS_FAILURE';

const fetchCertificationsRequest = () => ({
    type: FETCH_CERTIFICATIONS_REQUEST,
});

const fetchCertificationsSuccess = (data) => ({
    type: FETCH_CERTIFICATIONS_SUCCESS,
    payload: data,
});

const fetchCertificationsFailure = (error) => ({
    type: FETCH_CERTIFICATIONS_FAILURE,
    payload: error,
});

const CertificationsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_CERTIFICATIONS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_CERTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                error: '',
            };
        case FETCH_CERTIFICATIONS_FAILURE:
            return {
                loading: false,
                data: [],
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_CERTIFICATIONS_REDUCER = () => {
    return (dispatch) => {
        dispatch(fetchCertificationsRequest());
        GET_PROXY(CERTIFICATIONS_API, 
            (success) => {
            dispatch(fetchCertificationsSuccess(success?.data));
        }, 
        (error) => {
            dispatch(fetchCertificationsFailure(error));
            dispatch(fetchCertificationsFailure(''));
        })
    };
};

export default CertificationsReducer;
