import React from 'react'
import { IconButton, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import LinkComponent from '../LinkComponent';

const useStyles = makeStyles((theme) => ({
	iconBtn: {
		color: 'black',
		marginLeft: theme.spacing(1),
		[theme.breakpoints.down('md')]: {
			marginLeft: 6,
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: 12,
			marginRight: 12,
		},
	},
	icon: {
		width: 20,
		height: 20,
		[theme.breakpoints.down('md')]: {
			width: 16,
			height: 16,
		},
		[theme.breakpoints.down('sm')]: {
			width: 36,
			height: 36,
			'& path': {
				stroke: theme.palette.text.white,
			}
		},
	},
}))

const SocialHeaderBtn = ({ to, Icon, notExternal, classNameIcon, title}) => {
	const classes = useStyles();

	return (
		<LinkComponent to={to} external_link={!notExternal} title={title}>
			<IconButton className={classes.iconBtn} size='small' title={title}>
				<Icon className={classNameIcon || classes.icon} />
			</IconButton>
		</LinkComponent>
	)
}


SocialHeaderBtn.propTypes = {
	classNameIcon: PropTypes.string,
	to: PropTypes.string,
}
export default React.memo(SocialHeaderBtn);