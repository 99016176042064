import { CAREER_API } from '../constants/api';
import {  GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    postLoading: false,
    meta: {},
    data: [],
    error: '',
};

const FETCH_CAREER_REQUEST = 'FETCH_CAREER_REQUEST';
const FETCH_CAREER_POST_REQUEST = 'FETCH_CAREER_POST_REQUEST';
const FETCH_CAREER_SUCCESS = 'FETCH_CAREER_SUCCESS';
const FETCH_CAREER_FAILURE = 'FETCH_CAREER_FAILURE';

const fetchCareerRequest = () => ({
    type: FETCH_CAREER_REQUEST,
});
const fetchCareerPostRequest = () => ({
    type: FETCH_CAREER_POST_REQUEST,
});

const fetchCareerSuccess = (data) => ({
    type: FETCH_CAREER_SUCCESS,
    payload: data,
});

const fetchCareerFailure = (error) => ({
    type: FETCH_CAREER_FAILURE,
    payload: error,
});

const CareerReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_CAREER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_CAREER_POST_REQUEST:
            return {
                ...state,
                postLoading: true,
            };
        case FETCH_CAREER_SUCCESS:
            return {
                ...state,
                loading: false,
                postLoading: false,
                data: [
                    ...(payload?.meta?.current_page > 1 ? state?.data : []),
                    ...payload?.data
                ],
                meta: payload.meta,
                error: '',
            };
        case FETCH_CAREER_FAILURE:
            return {
                meta: {},
                loading: false,
                postLoading: false,
                data: {},
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_CAREER_REDUCER = (page) => {
    return (dispatch) => {
        if (page)
            dispatch(fetchCareerPostRequest());
        else
            dispatch(fetchCareerRequest());

        GET_PROXY(`${CAREER_API}?page=${page}`,
            (success) => {
                dispatch(fetchCareerSuccess(success));
            }, (error) => {
                dispatch(fetchCareerFailure(error));
                dispatch(fetchCareerFailure(''));
            })
    };
};

export default CareerReducer;
