import { SAFE_SHOPING_API } from '../constants/api';
import { GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    postLoading: false,
    meta: [],
    data: {},
    error: '',
};

const FETCH_SAFE_SHOPING_REQUEST = 'FETCH_SAFE_SHOPING_REQUEST';
const FETCH_SAFE_SHOPING_POST_REQUEST = 'FETCH_SAFE_SHOPING_POST_REQUEST';
const FETCH_SAFE_SHOPING_SUCCESS = 'FETCH_SAFE_SHOPING_SUCCESS';
const FETCH_SAFE_SHOPING_FAILURE = 'FETCH_SAFE_SHOPING_FAILURE';

const fetchSafeShoppingRequest = () => ({
    type: FETCH_SAFE_SHOPING_REQUEST,
});
const fetchSafeShoppingPostRequest = () => ({
    type: FETCH_SAFE_SHOPING_POST_REQUEST,
});

const fetchSafeShoppingSuccess = (data) => ({
    type: FETCH_SAFE_SHOPING_SUCCESS,
    payload: data,
});

const fetchSafeShoppingFailure = (error) => ({
    type: FETCH_SAFE_SHOPING_FAILURE,
    payload: error,
});

const SafeShoppingReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_SAFE_SHOPING_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_SAFE_SHOPING_POST_REQUEST:
            return {
                ...state,
                postLoading: true,
            };
        case FETCH_SAFE_SHOPING_SUCCESS:
            return {
                ...state,
                loading: false,
                postLoading: false,
                data: [
                    ...(payload?.meta?.current_page > 1 ? state?.data : []),
                    ...(payload?.data || []),
                ],
                meta: payload.meta,
                error: '',
            };
        case FETCH_SAFE_SHOPING_FAILURE:
            return {
                meta: {},
                loading: false,
                postLoading: false,
                data: [],
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_SAFE_SHOPING_REDUCER = (page) => {
    return (dispatch) => {
        if (page)
            dispatch(fetchSafeShoppingPostRequest());
        else
            dispatch(fetchSafeShoppingRequest());
        GET_PROXY(`${SAFE_SHOPING_API}?page=${page}`,
            (success) => {
                dispatch(fetchSafeShoppingSuccess(success));
            },
            (error) => {
                dispatch(fetchSafeShoppingFailure(error));
                dispatch(fetchSafeShoppingFailure(''));
            })
    };
};

export default SafeShoppingReducer;
