import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types";
import { useLocation, useParams } from "react-router-dom";
import { APP_URL } from "../../constants";
import { getParam } from "../../functions";
import AppStaticComponent from "../../components/AppStaticComponent";
import { useTranslation } from "react-i18next";


const SocialAuthCallbackPage = () => {
  const {t} = useTranslation();
 const {provider} = useParams();
 const { search } = useLocation();
 const url = useMemo(() => {
   const code = getParam('code', search);
  if(!provider || !code) {
    return null
  }
  return `${APP_URL}auth/${provider}/${code}`
  
 }, [provider, search])

 useEffect(() => {
  if(url) {
    window.location.href = url;
  }
  
 }, [url])

  return (
    <AppStaticComponent url={url} title={t('social_auth_callback.title')} description={t('social_auth_callback.description')} />
  );
};

SocialAuthCallbackPage.propTypes = {
   name: PropTypes.string,
};
export default React.memo(SocialAuthCallbackPage);
