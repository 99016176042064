import * as React from "react"

function SalesSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={73.183}
            height={53.546}
            viewBox="0 0 73.183 53.546"
            {...props}
        >
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 143">
                <g
                    data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 142"
                    fill="none"
                    className={'card-icon-stroke'}
                    stroke="#505357"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                >
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 243"
                        d="M.995 7.137A6.136 6.136 0 007.132 1h58.909a6.136 6.136 0 006.137 6.137v39.268a6.136 6.136 0 00-6.137 6.137H7.137a6.136 6.136 0 00-6.142-6.137z"
                    />
                    <path
                        data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 142"
                        d="M12.046 12.046h49.091v29.455H12.046z"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 244"
                        d="M.995 7.137A6.136 6.136 0 007.132 1h58.909a6.136 6.136 0 006.137 6.137v39.268a6.136 6.136 0 00-6.137 6.137H7.137a6.136 6.136 0 00-6.142-6.137z"
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 197"
                        d="M12.046 37.819v-2.455"
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 198"
                        d="M12.046 32.91v-2.455"
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 199"
                        d="M12.046 28v-2.454"
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 200"
                        d="M12.046 23.091v-2.454"
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 201"
                        d="M12.046 18.182v-2.454"
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 202"
                        d="M61.137 37.819v-2.455"
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 203"
                        d="M61.137 32.91v-2.455"
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 204"
                        d="M61.137 28v-2.454"
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 205"
                        d="M61.137 23.091v-2.454"
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 206"
                        d="M61.137 18.182v-2.454"
                    />
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 141">
                        <path
                            data-name="\u041B\u0438\u043D\u0438\u044F 207"
                            d="M26.773 36.591l19.636-19.636"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 245"
                            d="M34.137 20.637a3.682 3.682 0 11-3.682-3.682 3.683 3.683 0 013.682 3.682z"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 246"
                            d="M46.41 32.905a3.682 3.682 0 11-3.682-3.681 3.683 3.683 0 013.682 3.681z"
                        />
                    </g>
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 208"
                        d="M1 26.773h3.682"
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 209"
                        d="M68.501 26.773h3.682"
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 210"
                        d="M36.592 52.546v-3.682"
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 211"
                        d="M36.592 1v3.682"
                    />
                </g>
            </g>
        </svg>
    )
}

export default SalesSvg
