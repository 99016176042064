import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import Root from "./Root";
import mainTheme from "./themes/MainTheme";
import "moment/locale/pl";
import { CookiesProvider } from "react-cookie";
import { Suspense, useEffect, useState } from "react";
import Rellax from "rellax";
// import QrCode from "qrcode";
import { useSelector } from "react-redux";
import LoaderComponent from "./components/LoaderComponent";
import ServiceWorkerWrapperComponent from "./components/ServiceWorkerWrapperComponent";
import { BrowserRouter } from "react-router-dom";
// import { generateShopsQr } from "./functions";

function App() {
  const [load, setLoad] = useState(false);
  const { palette } = useSelector((state) => state.MainReducer.data);

  useEffect(() => {
    setLoad(true);
    // moment.locale('pl');
  }, []);

  useEffect(() => {
    if (load) {
      // eslint-disable-next-line
      const rellax = new Rellax(".rellax", {
        speed: 3,
      });
    }
  }, [load]);

  return (
    <CookiesProvider>
      <MuiThemeProvider
        theme={{
          ...mainTheme,
          palette: {
            ...mainTheme.palette,
            ...(palette ? palette : {}),
          },
        }}
      >
        <CssBaseline />
        <Suspense fallback={<LoaderComponent />}>
          <BrowserRouter>
            <Root />
          </BrowserRouter>
        </Suspense>
      </MuiThemeProvider>
      <ServiceWorkerWrapperComponent />
    </CookiesProvider>
  );
}

export default App;
