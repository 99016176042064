import { MENU_API } from "../constants/api";
import {  GET_PROXY } from "../functions/superagentSending";

const MENU_ENDPOINT = 'menu'
const initialState = {
    loading: false,
    data: {},
    error: '',
};


const UPDATE_MAIN_SUCCESS = 'UPDATE_MAIN_SUCCESS'
const FETCH_MENU_REQUEST = 'FETCH_MENU_REQUEST';
// const FETCH_MENU_FAILURE = 'FETCH_MENU_FAILURE';
const FETCH_MENU_SUCCESS = 'FETCH_MENU_SUCCESS';

const updateMainSuccess = (id, value) => ({
    type: UPDATE_MAIN_SUCCESS,
    payload: { id, value }
})


const fetchMenuRequest = () => ({
    type: FETCH_MENU_REQUEST
})

const fetchMenuSuccess = data => ({
    type: FETCH_MENU_SUCCESS,
    payload: data
})


// const fetchMenuFailure = error => ({
//     type: FETCH_MENU_FAILURE,
//     payload: error
// })


const MainReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_MAIN_SUCCESS:
            return {
                data: {
                    ...state.data,
                    [payload.id]: payload.value,
                },
                loading: false,
                error: ''
            }
        case FETCH_MENU_SUCCESS:
            return {
                data: {
                    ...state.data,
                    ...payload,
                },
                loading: false,
                error: ''
            }
        default:
            return state
    }
}

export const UPDATE_MAIN_REDUCER = (id, value) => {
    return (dispatch) => {
        dispatch(updateMainSuccess(id, value));
    }
}

export const FETCH_MENU_REDUCER = (slug) => {
    return (dispatch) => {
        dispatch(fetchMenuRequest())

        GET_PROXY(MENU_API, success => {
            dispatch(fetchMenuSuccess(success?.data))
            localStorage.setItem(MENU_ENDPOINT, JSON.stringify(success?.data));
            localStorage.setItem('updateMenu', new Date().getTime())
        }, error => {
            const data = localStorage.getItem(MENU_ENDPOINT) ? JSON.parse(localStorage.getItem(MENU_ENDPOINT)) : false;
            if (data) {
                dispatch(fetchMenuSuccess(data))
            } else {
            }
        })
    }
}



export default MainReducer;