import { GASTRONOMY_API } from '../constants/api';
import {  GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    data: {},
    error: '',
};

const FETCH_GASTRONOMY_SHOP_REQUEST = 'FETCH_GASTRONOMY_SHOP_REQUEST';
const FETCH_GASTRONOMY_SHOP_SUCCESS = 'FETCH_GASTRONOMY_SHOP_SUCCESS';
const FETCH_GASTRONOMY_SHOP_FAILURE = 'FETCH_GASTRONOMY_SHOP_FAILURE';

const fetchGastronomyShopRequest = () => ({
    type: FETCH_GASTRONOMY_SHOP_REQUEST,
});

const fetchGastronomyShopSuccess = (data) => ({
    type: FETCH_GASTRONOMY_SHOP_SUCCESS,
    payload: data,
});

const fetchGastronomyShopFailure = (error) => ({
    type: FETCH_GASTRONOMY_SHOP_FAILURE,
    payload: error,
});

const GastronomyShopReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_GASTRONOMY_SHOP_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_GASTRONOMY_SHOP_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                error: '',
            };
        case FETCH_GASTRONOMY_SHOP_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_GASTRONOMY_SHOP_REDUCER = (slug) => {
    return (dispatch) => {
        dispatch(fetchGastronomyShopRequest());
        GET_PROXY(`${GASTRONOMY_API}/${slug}`,
            (success) => {
                dispatch(fetchGastronomyShopSuccess(success?.data));
            },
            (error) => {
                dispatch(fetchGastronomyShopFailure(error));
                dispatch(fetchGastronomyShopFailure(''));
            })
    };
};

export default GastronomyShopReducer;
