import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import CircleComponent from '../../../components/CircleComponent';
import FlowerImg from '../../../imgs/salesImg/flower.webp'
import CraneImg from '../../../imgs/salesImg/crane.webp'
import CircleImg from '../../../imgs/salesImg/circle.webp'
// import BirdImg from '../../../imgs/salesImg/bird.webp'
import { useTranslation } from 'react-i18next';
import AnimImgComponent from '../../../components/AnimImgComponent';

const biggestCircle = 350;
const useStyles = makeStyles((theme) => ({
    cont: {
        position: 'relative',
        height: 760,
        width: '90%',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    crane: {
        width: biggestCircle + 100,
        bottom: biggestCircle / 2,
        zIndex: 2,
    },
    circle: {
        left: biggestCircle - biggestCircle / 2,
        width: biggestCircle - 50,
    },
    flower: {
        height: biggestCircle - 50,
        bottom: 0,
        zIndex: 3,
        left: 20,
    },
    bird: {
        width: 240,
        zIndex: 4,
        left: biggestCircle - 60,
        bottom: biggestCircle + biggestCircle / 2,
    },
}))

const SalesAnimElComponent = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const id = 'salesAnimation'
    return (
        <section className={classes.cont} id={id} >
            <AnimImgComponent
                src={FlowerImg}
                parentId={id}
                id={'flower'}
                from={{
                    opacity: 0,
                }}
                to={{
                    opacity: 1,
                }}
                loading={'eager'}
                start={'top center+=600'}
                alt={t('main_page.flower')}
                className={classes.flower}
            />
            <AnimImgComponent
                src={CircleImg}
                parentId={id}
                loading={'eager'}
                // id={'circle'}
                alt={t('main_page.circle')}
                className={classes.circle}
            />
            <AnimImgComponent
                src={CraneImg}
                parentId={id}
                id={'crane'}
                // loading={'eager'}
                delay={2}
                from={{
                    opacity: 0,
                    transform: 'rotate(315deg)',
                    left: -200,
                    bottom: (biggestCircle / 3),
                }}
                to={{
                    opacity: 1,
                    transform: 'rotate(0deg)',
                    left: -10,
                    bottom: (biggestCircle / 2) - 15,
                }}
                start={'top center+=200'}
                alt={t('main_page.crane')}
                className={classes.crane}
            />
            <CircleComponent
                size={biggestCircle}
                bottom={20}
                left={0}
                color={theme.palette.other.yellow}
            />
            <CircleComponent
                size={50}
                right={0}
                rellax
                bottom={biggestCircle - 50}
                color={theme.palette.other.yellow}
            />
        </section>
    )
}


SalesAnimElComponent.propTypes = {
    name: PropTypes.string,
}
export default React.memo(SalesAnimElComponent);