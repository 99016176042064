import React from 'react'
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import LinkBtnComponent from './LinkBtnComponent';

const useStyles = makeStyles((theme) => ({
    cont: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'baseline',
            flexDirection: 'column-reverse',
        },
    },
    title: {
        marginBottom: 80,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 40,
        },
    },
 
    btn: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20,
        },
    },
}))

const SubTitleComponent = ({ title, to }) => {
    const classes = useStyles();
    return (
        <section className={classes.cont}>
            <Typography
                variant='h2'
                className={classes.title}
                dangerouslySetInnerHTML={{ __html: title }}
            />

            {to
                ? <LinkBtnComponent
                    to={to}
                    className={classes.btn}
                />
                : null
            }
        </section>
    )
}


SubTitleComponent.propTypes = {
    title: PropTypes.string,
    to: PropTypes.string,
}
export default React.memo(SubTitleComponent);