import React from 'react'
import { Box, Grid, makeStyles, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SQUARE } from '../../constants/csses';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    paper: {
        ...SQUARE,
    },
    item: {
        padding: theme.spacing(1)
    },
    box: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    }
}))

const SquarePaperSkeletonComponent = ({ sm = 6, md = 3, xs = 6, }) => {
    const classes = useStyles();

    return (
        <Grid md={md} sm={sm} xs={xs} item className={classes.item}>
            <Paper className={classes.paper} elevation={2}>
                <Box className={classes.box}>
                    <Skeleton variant="rect" height='100%' />
                </Box>
            </Paper>
        </Grid>
    )
}


SquarePaperSkeletonComponent.propTypes = {
    sm: PropTypes.number,
    md: PropTypes.number,
    xs: PropTypes.number,
}
export default React.memo(SquarePaperSkeletonComponent);