import { VIDEO_API } from '../constants/api';
import {  GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    meta: {},
    data: [],
    error: '',
};

const FETCH_VIDEOS_REQUEST = 'FETCH_VIDEOS_REQUEST';
const FETCH_VIDEOS_SUCCESS = 'FETCH_VIDEOS_SUCCESS';
const FETCH_VIDEOS_FAILURE = 'FETCH_VIDEOS_FAILURE';

const fetchVideosRequest = () => ({
    type: FETCH_VIDEOS_REQUEST,
});

const fetchVideosSuccess = (data) => ({
    type: FETCH_VIDEOS_SUCCESS,
    payload: data,
});

const fetchVideosFailure = (error) => ({
    type: FETCH_VIDEOS_FAILURE,
    payload: error,
});

const VideosReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_VIDEOS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_VIDEOS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: [

                    ...(payload?.meta?.current_page > 1 ? state?.data : []),
                    ...(payload?.data || []),
                ],
                meta: payload.meta,
                error: '',
            };
        case FETCH_VIDEOS_FAILURE:
            return {
                meta: {},
                loading: false,
                data: [],
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_VIDEOS_REDUCER = (page) => {
    return (dispatch) => {
        dispatch(fetchVideosRequest());
        GET_PROXY(`${VIDEO_API}?page=${page}`, (success) => {
            dispatch(fetchVideosSuccess(success));
        },
            (error) => {
                dispatch(fetchVideosFailure(error));
                dispatch(fetchVideosFailure(''));
            })
    };
};

export default VideosReducer;
