import loadable from '@loadable/component'



const AboutPage = loadable(() => import("../pages/aboutPage/AboutPage"));
const ArticlePage = loadable(() => import("../pages/articlePage/ArticlePage"));
const CareerOfferPage = loadable(() => import("../pages/careerOfferPage/CareerOfferPage"));
const CareerPage = loadable(() => import("../pages/careerPage/CareerPage"));
const CloakroomPage = loadable(() => import("../pages/cloakroomPage/CloakroomPage"));
const ComfortPage = loadable(() => import("../pages/comfortPage/ComfortPage"));
const ContactPage = loadable(() => import("../pages/contactPage/ContactPage"));
const CustomPage = loadable(() => import("../pages/customPage/CustomPage"));
const EmptyPage = loadable(() => import("../pages/emptyPage/EmptyPage"));
const EntertainmentPage = loadable(() => import("../pages/entertainmentPage/EntertainmentPage"));
const FashionInspirationPage = loadable(() => import("../pages/fashionInspirationPage/FashionInspirationPage"));
const GalleryPage = loadable(() => import("../pages/galleryPage/GalleryPage"));
const GastronomyPage = loadable(() => import("../pages/gastronomyPage/GastronomyPage"));
const GastronomyShopPage = loadable(() => import("../pages/gastronomyShopPage/GastronomyShopPage"));
const GiftCardPage = loadable(() => import("../pages/giftCardPage/GiftCardPage"));
const HappyPortPage = loadable(() => import("../pages/happyPortPage/HappyPortPage"));
const InformationPage = loadable(() => import("../pages/informationPage/InformationPage"));
const LocalizationPage = loadable(() => import("../pages/localizationPage/LocalizationPage"));
const NewsPage = loadable(() => import("../pages/newsPage/NewsPage"));
const NumberOnePage = loadable(() => import("../pages/numberOnePage/NumberOnePage"));
const ParkingsPage = loadable(() => import("../pages/parkingsPage/ParkingsPage"));
const PlanPage = loadable(() => import("../pages/planPage/PlanPage"));
const PromotionPage = loadable(() => import("../pages/promotionPage/PromotionPage"));
const PromotionsPage = loadable(() => import("../pages/promotionsPage/PromotionsPage"));
const SafeShoppingPage = loadable(() => import("../pages/safeShoppingPage/SafeShoppingPage"));
const SalesTablePage = loadable(() => import("../pages/salesTablePage/SalesTablePage"));
const ServicePage = loadable(() => import("../pages/servicePage/ServicePage"));
const ServicesPage = loadable(() => import("../pages/servicesPage/ServicesPage"));
const ShopPage = loadable(() => import("../pages/shopPage/ShopPage"));
const ShopsPage = loadable(() => import("../pages/shopsPage/ShopsPage"));
const SpecialPromotionsPage = loadable(() => import("../pages/specialPromotionsPage/SpecialPromotionsPage"));
const StylistTipsPage = loadable(() => import("../pages/stylistTipsPage/StylistTipsPage"));
const EventsPage = loadable(() => import("../pages/eventsPage/EventsPage"));
const EventPage = loadable(() => import("../pages/eventPage/EventPage"));
const BabyroomPage = loadable(() => import("../pages/babyroomPage/BabyroomPage"));
const QuestionnairePage = loadable(() => import("../pages/questionnairePage/QuestionnairePage"));
const GalleriesPage = loadable(() => import("../pages/galleriesPage/GalleriesPage"));
const VideosPage = loadable(() => import("../pages/videosPage/VideosPage"));

const preloadPages = () => {
    AboutPage.preload()
    ArticlePage.preload()
    CareerOfferPage.preload()
    CareerPage.preload()
    CloakroomPage.preload()
    ComfortPage.preload()
    ContactPage.preload()
    CustomPage.preload()
    EmptyPage.preload()
    EntertainmentPage.preload()
    FashionInspirationPage.preload()
    GalleryPage.preload()
    GastronomyPage.preload()
    GastronomyShopPage.preload()
    GiftCardPage.preload()
    HappyPortPage.preload()
    InformationPage.preload()
    LocalizationPage.preload()
    NewsPage.preload()
    NumberOnePage.preload()
    ParkingsPage.preload()
    PlanPage.preload()
    PromotionPage.preload()
    PromotionsPage.preload()
    SafeShoppingPage.preload()
    SalesTablePage.preload()
    ServicePage.preload()
    ServicesPage.preload()
    ShopPage.preload()
    SpecialPromotionsPage.preload()
    EventsPage.preload()
    ShopsPage.preload()
    StylistTipsPage.preload()
    EventPage.preload()
    BabyroomPage.preload()
    QuestionnairePage.preload()
    GalleriesPage.preload()
    VideosPage.preload()
}

export default preloadPages;