import { EVENTS_API } from '../constants/api';
import { GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    postLoading: false,
    meta: {},
    data: [],
    error: '',
};

const FETCH_EVENTS_REQUEST = 'FETCH_EVENTS_REQUEST';
const FETCH_EVENTS_POST_REQUEST = 'FETCH_EVENTS_POST_REQUEST';
const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE';

const fetchEventsRequest = () => ({
    type: FETCH_EVENTS_REQUEST,
});
const fetchEventsPostRequest = () => ({
    type: FETCH_EVENTS_POST_REQUEST,
});

const fetchEventsSuccess = (data) => ({
    type: FETCH_EVENTS_SUCCESS,
    payload: data,
});

const fetchEventsFailure = (error) => ({
    type: FETCH_EVENTS_FAILURE,
    payload: error,
});

const EventsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_EVENTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_EVENTS_POST_REQUEST:
            return {
                ...state,
                postLoading: true,
            };
        case FETCH_EVENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                postLoading: false,
                data: [
                    ...(payload?.meta?.current_page > 1 ? state?.data : []),
                    ...payload?.data
                ],
                meta: payload.meta,
                error: '',
            };
        case FETCH_EVENTS_FAILURE:
            return {
                meta: {},
                loading: false,
                postLoading: false,
                data: {},
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_EVENTS_REDUCER = (page) => {
    return (dispatch) => {
        if (page)
            dispatch(fetchEventsPostRequest());
        else
            dispatch(fetchEventsRequest());
        GET_PROXY(`${EVENTS_API}?page=${page}`,
            (success) => {
                dispatch(fetchEventsSuccess(success));
            },
            (error) => {
                dispatch(fetchEventsFailure(error));
                dispatch(fetchEventsFailure(''));
            })
    };
};

export default EventsReducer;
