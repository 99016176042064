import * as React from "react"

function EssentialBackSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={1921.561}
            height={825.605}
            viewBox="0 0 1921.561 825.605"
            {...props}
        >
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 569"
                d="M0 0l1921.561 1v753.859s-464.552 92.125-944.942 66.12S0 729.379 0 729.379z"
                fill="#f0f0f0"
            />
        </svg>
    )
}

export default EssentialBackSvg
