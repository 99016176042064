import { HELP_UKRAINE_API } from '../constants/api';
import { GET_PROXY } from '../functions/superagentSending';

const initialState = {
    loading: true,
    postLoading: false,
    meta: {},
    data: [],
    error: '',
};

const FETCH_HELP_UKRAINE_REQUEST = 'FETCH_HELP_UKRAINE_REQUEST';
const FETCH_HELP_UKRAINE_POST_REQUEST = 'FETCH_HELP_UKRAINE_POST_REQUEST';
const FETCH_HELP_UKRAINE_SUCCESS = 'FETCH_HELP_UKRAINE_SUCCESS';
const FETCH_HELP_UKRAINE_FAILURE = 'FETCH_HELP_UKRAINE_FAILURE';

const fetchHelpUkraineRequest = () => ({
    type: FETCH_HELP_UKRAINE_REQUEST,
});
const fetchHelpUkrainePostRequest = () => ({
    type: FETCH_HELP_UKRAINE_POST_REQUEST,
});

const fetchHelpUkraineSuccess = (data) => ({
    type: FETCH_HELP_UKRAINE_SUCCESS,
    payload: data,
});

const fetchHelpUkraineFailure = (error) => ({
    type: FETCH_HELP_UKRAINE_FAILURE,
    payload: error,
});

const HelpUkraineReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_HELP_UKRAINE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_HELP_UKRAINE_POST_REQUEST:
            return {
                ...state,
                postLoading: true,
            };
        case FETCH_HELP_UKRAINE_SUCCESS:
            return {
                ...state,
                loading: false,
                postLoading: false,
                data: [
                    ...(payload?.meta?.current_page > 1 ? state?.data : []),
                    ...payload?.data
                ],
                meta: payload.meta,
                error: '',
            };
        case FETCH_HELP_UKRAINE_FAILURE:
            return {
                meta: {},
                loading: false,
                postLoading: false,
                data: {},
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_HELP_UKRAINE_REDUCER = (page) => {
    return (dispatch) => {
        if (page)
            dispatch(fetchHelpUkrainePostRequest());
        else
            dispatch(fetchHelpUkraineRequest());
        GET_PROXY(`${HELP_UKRAINE_API}?page=${page}`,
            (success) => {
                dispatch(fetchHelpUkraineSuccess(success));
            },
            (error) => {
                dispatch(fetchHelpUkraineFailure(error));
                dispatch(fetchHelpUkraineFailure(''));
            })
    };
};

export default HelpUkraineReducer;
