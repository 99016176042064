import React from 'react'
import { useEffect } from 'react';
import * as serviceWorker from '../serviceWorkerRegistration';

const ServiceWorkerWrapperComponent = (props) => {
	
	// const onSWUpdate = (registration) => {
	// 	registration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
	// 	// window.location.reload(true);
	// };

	useEffect(() => {
		// serviceWorker.register({ onUpdate: onSWUpdate });
	}, []);
	serviceWorker.unregister();
	return (
		<>
			
		</>
	)
}

export default React.memo(ServiceWorkerWrapperComponent);