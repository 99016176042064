import { useTheme } from "@material-ui/core"
import * as React from "react"

function LogoSvg(props) {
    const theme = useTheme();
    return (
        <svg
            data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 181"
            xmlns="http://www.w3.org/2000/svg"
            width={108.988}
            height={70.6}
            viewBox="0 0 108.988 70.6"
            {...props}
        >
            <path
                data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 151"
                fill={theme.palette.primary.light}
                d="M38.388 0h70.6v70.6h-70.6z"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 284"
                d="M44.353 60.354v1.263h1.544v.632h-1.544v1.333h1.965v.632h-2.807v-4.492h2.807v.632z"
                fill="#fff"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 285"
                d="M50.108 60.564c0 .07-.07.07 0 0l-.14.07c-.07 0-.07 0-.14-.07-.07 0-.14-.07-.211-.14s-.14-.07-.281-.14a3.039 3.039 0 00-.351-.07c-.14 0-.211 0-.281.07-.07 0-.14.07-.211.14s-.07.14-.14.211c0 .07-.07.14-.07.211 0 .14 0 .211.07.281l.211.211c.07.07.211.07.351.14s.281.07.351.14c.14.07.281.07.351.14.14.07.211.14.351.211a.6.6 0 01.211.351 1.04 1.04 0 01.07.491 1.368 1.368 0 01-.07.561 1.075 1.075 0 01-.772.772 2.049 2.049 0 01-.632.14.89.89 0 01-.421-.07 1 1 0 01-.421-.14 1.534 1.534 0 01-.351-.211.969.969 0 01-.281-.211l.281-.421.07-.07h.14c.07 0 .14 0 .14.07.07.07.14.07.211.14s.211.07.281.14a.76.76 0 00.421.07 1.322 1.322 0 00.561-.14.637.637 0 00.211-.491c0-.14 0-.211-.07-.281l-.211-.211c-.07-.07-.211-.07-.351-.14s-.281-.07-.351-.14c-.14-.07-.281-.07-.351-.14-.14-.07-.211-.14-.351-.211a.6.6 0 01-.211-.351 1.041 1.041 0 01-.07-.491 1.194 1.194 0 01.07-.491 1.468 1.468 0 01.281-.421 1.131 1.131 0 01.491-.281 2.049 2.049 0 01.632-.14 2.985 2.985 0 01.772.14 1.528 1.528 0 01.561.351z"
                fill="#fff"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 286"
                d="M52.564 60.354v1.263h1.614v.632h-1.614v1.333h2.035v.632h-2.877v-4.492h2.877v.632z"
                fill="#fff"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 287"
                d="M59.793 59.722v4.491h-.56c-.07 0-.07-.07-.14-.14l-2.386-3.018v3.158h-.774v-4.491h.561a.069.069 0 01.07.07l.07.07 2.386 3.018v-3.158z"
                fill="#fff"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 288"
                d="M64.495 63.161c.07 0 .07 0 .14.07l.351.351a1.6 1.6 0 01-.7.491 2.038 2.038 0 01-.983.211 3.556 3.556 0 01-.912-.14 2.521 2.521 0 01-.7-.491 1.456 1.456 0 01-.421-.7 3.035 3.035 0 010-1.825 2.465 2.465 0 011.193-1.193 1.926 1.926 0 01.912-.14 2.618 2.618 0 01.842.14 2.449 2.449 0 01.632.421l-.281.421-.07.07h-.211a.245.245 0 00-.14-.07c-.07 0-.07-.07-.14-.07s-.14-.07-.211-.07-.14-.07-.211-.07h-.281a1.322 1.322 0 00-.561.14c-.14.07-.281.211-.421.281a1.131 1.131 0 00-.281.491 2.163 2.163 0 00-.07.7 2.076 2.076 0 00.14.7 1.131 1.131 0 00.281.491 1.468 1.468 0 00.421.281 1.322 1.322 0 00.561.14h.281a.422.422 0 00.281-.07c.07 0 .14-.07.211-.07.07-.07.14-.07.211-.14a.069.069 0 00.07-.07c0-.281.07-.281.07-.281"
                fill="#fff"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 289"
                d="M67.864 62.6a2.164 2.164 0 01-.07.7 1.131 1.131 0 01-.281.491 1.259 1.259 0 01-.491.351 2.049 2.049 0 01-.632.14h-.351a.633.633 0 01-.351-.07v-.561c0-.07 0-.07.07-.14a.245.245 0 01.14-.07h.351a.633.633 0 00.351-.07c.07 0 .14-.07.211-.14a.365.365 0 00.14-.281c0-.14.07-.281.07-.421v-2.947h.842l.001 3.018z"
                fill="#fff"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 290"
                d="M71.794 62.529l-.561-1.474c0-.07-.07-.14-.07-.211s-.07-.211-.07-.281c0 .14-.07.211-.07.281s-.07.14-.07.211l-.561 1.474zm1.474 1.684h-.632a.258.258 0 01-.211-.07c-.07-.07-.07-.07-.07-.14l-.351-.912h-1.895l-.351.912c0 .07-.07.07-.07.14a.258.258 0 01-.211.07h-.631l1.754-4.491h.842z"
                fill="#fff"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 291"
                d="M81.197 59.722v4.491h-.772v-3.3l-1.333 2.6c-.07.14-.14.211-.281.211h-.14c-.14 0-.211-.07-.281-.211l-1.4-2.6v3.369h-.776v-4.49h.772a.069.069 0 01.07.07l.07.07 1.333 2.526a.258.258 0 01.07.211c0 .07.07.14.07.211 0-.07.07-.14.07-.211s.07-.14.07-.211l1.333-2.526a.069.069 0 01.07-.07.069.069 0 00.07-.07h.983v-.07z"
                fill="#fff"
            />
            <path
                data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 152"
                fill="#fff"
                d="M82.811 59.722h.842v4.491h-.842z"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 292"
                d="M87.653 62.529l-.561-1.474c0-.07-.07-.14-.07-.211s-.07-.211-.07-.281c0 .14-.07.211-.07.281s-.07.14-.07.211l-.561 1.474zm1.474 1.684h-.632a.258.258 0 01-.211-.07c-.07-.07-.07-.07-.07-.14l-.351-.912h-1.895l-.351.912c0 .07-.07.07-.07.14a.258.258 0 01-.211.07h-.56l1.754-4.491h.842z"
                fill="#fff"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 293"
                d="M92.636 60.564a.069.069 0 01-.07.07h-.07c-.07 0-.07 0-.14-.07-.07 0-.14-.07-.211-.14s-.14-.07-.281-.14a3.039 3.039 0 00-.351-.07c-.14 0-.211 0-.281.07-.07 0-.14.07-.211.14s-.07.14-.14.211c0 .07-.07.14-.07.211 0 .14 0 .211.07.281l.211.211c.07.07.211.07.351.14s.281.07.351.14c.14.07.281.07.351.14.14.07.211.14.351.211a.6.6 0 01.211.351 1.041 1.041 0 01.07.491 1.368 1.368 0 01-.07.561 1.075 1.075 0 01-.772.772 2.049 2.049 0 01-.632.14.89.89 0 01-.421-.07 1 1 0 01-.421-.14 1.534 1.534 0 01-.351-.211.968.968 0 01-.281-.211l.281-.421.07-.07h.14c.07 0 .14 0 .14.07.07.07.14.07.211.14s.211.07.281.14a.76.76 0 00.421.07 1.322 1.322 0 00.561-.14.637.637 0 00.211-.491c0-.14 0-.211-.07-.281l-.211-.211c-.07-.07-.211-.07-.351-.14s-.281-.07-.351-.14c-.14-.07-.281-.07-.351-.14-.14-.07-.211-.14-.351-.211a.6.6 0 01-.211-.351 1.041 1.041 0 01-.07-.491 1.194 1.194 0 01.07-.491 1.468 1.468 0 01.281-.421 1.131 1.131 0 01.491-.281 2.049 2.049 0 01.632-.14 2.985 2.985 0 01.772.14 1.528 1.528 0 01.561.351z"
                fill="#fff"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 294"
                d="M97.479 60.422h-1.4v3.79h-.842v-3.79H93.9v-.7h3.579z"
                fill="#fff"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 295"
                d="M100.637 62.529l-.561-1.474c0-.07-.07-.14-.07-.211s-.07-.211-.07-.281c0 .14-.07.211-.07.281s-.07.14-.07.211l-.561 1.474zm1.474 1.684h-.632a.258.258 0 01-.211-.07c-.07-.07-.07-.07-.07-.14l-.351-.912h-1.895l-.351.912c0 .07-.07.07-.07.14a.258.258 0 01-.211.07h-.631l1.754-4.491h.846z"
                fill="#fff"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 296"
                d="M103.935 59.722v2.316c0 .211-.07.351-.07.561h-.491c0-.211-.07-.421-.07-.561v-2.316zm-.842 4.07a.258.258 0 01.07-.211.245.245 0 01.07-.14c.07-.07.07-.07.14-.07s.14-.07.211-.07a.258.258 0 01.211.07.245.245 0 01.14.07c.07.07.07.07.07.14s.07.14.07.211a.258.258 0 01-.07.211.245.245 0 01-.07.14c-.07.07-.07.07-.14.07s-.14.07-.211.07a.258.258 0 01-.211-.07.245.245 0 01-.14-.07c-.07-.07-.07-.07-.07-.14a.258.258 0 01-.07-.211"
                fill="#fff"
            />
            <path
                data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 153"
                fill="#575756"
                d="M0 5.965h38.388v47.301H0z"
            />
            <path
                data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 154"
                fill={theme.palette.primary.dark}
                d="M38.388 5.965h31.3v47.301h-31.3z"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 297"
                d="M42.458 35.931c.211 0 .281-.07.281-.281 0-.07-1.614-4.141-4.913-12.281a.217.217 0 00-.14-.07h-1.264c-.07 0-.14.07-.211.07l-12 12.141c-.14.14-.14.211-.14.211 0 .07.07.14.211.14h9.123c.14 0 .211-.07.281-.281.351-1.123.491-1.754.491-1.754 0-.07-.07-.14-.211-.14H27.65l5.9-6.035 3.439 8.141c0 .07.07.07.14.07l5.334.07z"
                fill="#fff"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 298"
                d="M23.37 35.931c.14 0 .211-.07.281-.281 1.474-5.263 2.246-7.93 2.246-7.93 0-.14-.07-.211-.14-.211h-4.282a.257.257 0 00-.281.281l-1.684 5.965h-1.334q-.842 0-.842-1.053a7.965 7.965 0 01.491-2.316l.561-2.035a14.159 14.159 0 01.772-2.035 1.622 1.622 0 011.4-.842h4.983c.14 0 .211-.07.281-.281l.491-1.684v-.07a.151.151 0 00-.14-.14h-7.65a4.44 4.44 0 00-3.369 1.895 13.023 13.023 0 00-2.176 4.421 9.252 9.252 0 00-.486 2.948 4.336 4.336 0 00.632 2.526 2 2 0 001.684.842z"
                fill="#fff"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 299"
                d="M89.618 30.387l6.6-6.737c.14-.07.14-.14.14-.211 0-.14-.07-.14-.211-.14h-1.544c-.07 0-.14.07-.211.07l-4.632 4.772-1.895-4.772c-.07-.07-.07-.07-.14-.07h-5.334c-.211 0-.281.07-.281.281 0 .07.912 2.316 2.737 6.948l-1.474 5.263a.151.151 0 00.14.14h4.281c.14 0 .211-.07.281-.281z"
                fill="#fff"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 300"
                d="M79.373 23.229c-.7.772-1.474 1.474-2.246 2.246-.281-.632-.561-1.4-.842-2.105 0-.07-.07-.07-.14-.07l-5.9.07 2.6 6.176c-1.544 1.4-2.948 2.6-4.141 3.649-.842-2.035-2.105-5.263-3.93-9.825a.217.217 0 00-.14-.07h-1.263c-.07 0-.14.07-.211.07L52.915 33.759h-4.7c-.632 0-.912-.281-.912-.912a7.564 7.564 0 01.421-1.965l2.105-7.3v-.07a.151.151 0 00-.14-.14h-4.564c-.14 0-.211.07-.281.281l-1.825 6.316a9.937 9.937 0 00-.421 2.667 3.543 3.543 0 00.7 2.456 2 2 0 001.684.842h15.439c.14 0 .211-.07.281-.281.351-1.123.491-1.754.491-1.754a.151.151 0 00-.14-.14H54.74l5.9-6.035 3.434 8.135c0 .07.07.07.14.07h5.334c.211 0 .281-.07.281-.281a2.951 2.951 0 01-.14-.351c1.193-1.123 2.6-2.456 4.07-3.93l1.895 4.421c0 .07.07.07.14.07h5.334c.211 0 .281-.07.281-.281 0-.07-1.123-2.948-3.439-8.562a57.53 57.53 0 003.369-3.86z"
                fill="#fff"
            />
        </svg>
    )
}

export default LogoSvg
