import React from 'react'
import { Container, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { classNames } from '../../../functions'
import SubtitleComponent from '../../../components/SubtitleComponent';

const useStyles = makeStyles((theme) => ({
    section: {
        position: 'relative',
        // overflowX: 'hidden',
    },
    background: {
        minWidth: '100%',
        height: 'auto',
        position: 'absolute',
        zIndex: 0,
    },
    cont: {
        position: 'relative',
        zIndex: 1,
        paddingTop: 160,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 80,
        }
    },
}))

const SectionComponent = ({ id, title, children, moreTo, Background, animElements, classNameBack = '', classNameSection = '', classNameCont = '' }) => {
    const classes = useStyles();

    return (
        <section id={id} className={classNames([classes.section, classNameSection])}>
         
            {Background
                ? <Background className={classNames([classes.background, classNameBack])} />
                : null
            }
            <Container className={classNames([classes.cont, classNameCont])}>
                <SubtitleComponent
                    title={title}
                    to={moreTo}
                />
                {children}
            </Container>
            {animElements
                ? animElements
                : null
            }
        </section>
    )
}


SectionComponent.propTypes = {
    title: PropTypes.string,
    id: PropTypes.number,
    moreTo: PropTypes.string,
    Background: PropTypes.func,
    classNameBack: PropTypes.string,
    classNameCont: PropTypes.string,
    classNameSection: PropTypes.string,
}
export default React.memo(SectionComponent);